import { TDispatchAction, TStateStore } from 'app/AppStateStore';

export const selectFuseDialogState = ({ fuse }: TStateStore) =>
  fuse.dialog.state as boolean;

export const selectFuseDialogOptions = ({ fuse }: TStateStore) =>
  fuse.dialog.options as TDialogOptions;

export const openDialog: TDispatchAction<TDialogOptions> =
  (payload) => (store) => {
    store.fuse.dialog.state = true;
    store.fuse.dialog.options = payload;
  };

export const closeDialog: TDispatchAction<void> = () => (store) => {
  store.fuse.dialog.state = false;
};

type TDialogOptions = {
  title: string;
  content: string;
  onConfirm: () => void;
  onClose?: () => void;
};
