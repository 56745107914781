import { Dialog, DialogContentText, DialogTitle } from '@mui/material';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useDispatch, useSelector } from 'app/AppStateStore';
import {
  closeDialog,
  selectFuseDialogOptions,
  selectFuseDialogState,
} from 'app/store/fuse/dialogSlice';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const CustomDialog: FC = () => {
  const { t } = useTranslation('dialog');
  const dispatch = useDispatch();
  const state = useSelector(selectFuseDialogState);
  const options = useSelector(selectFuseDialogOptions);

  const onClose = () => dispatch(closeDialog());
  return (
    <Dialog open={!!state} onClose={options.onClose || onClose}>
      <DialogTitle>{t('confirmAction')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{options.content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={options.onClose || onClose} color="error">
          {t('cancel')}
        </Button>
        <Button onClick={options.onConfirm} color="success">
          {t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
