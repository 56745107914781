import FuseMessage from '@fuse/core/FuseMessage';
import FuseSuspense from '@fuse/core/FuseSuspense';
import styled from '@mui/material/styles/styled';
import { TLayoutConfig, useSelector } from 'app/AppStateStore';
import CustomDialog from 'app/components/shared/CustomDialog';
import { selectLayoutConfig, selectRoutes } from 'app/store/fuse/settingsSlice';
import { FC, memo } from 'react';
import { RouteObject, useRoutes } from 'react-router-dom';
import FooterAdmin from './components/FooterAdmin';
import LeftSideAdmin from './components/LeftSideAdmin';
import NavbarWrapperAdmin from './components/NavbarWrapperAdmin';
import RightSideAdmin from './components/RightSideAdmin';
import ToolbarAdmin from './components/ToolbarAdmin';

const AdminLayout: FC<React.PropsWithChildren> = (props) => {
  const config = useSelector(selectLayoutConfig);
  const routes = useSelector(selectRoutes);

  return (
    <Root id="fuse-layout" config={config} className="w-full flex">
      {config.leftSidePanel.display && <LeftSideAdmin />}

      <div className="flex flex-auto min-w-0">
        {config.navbar.display && config.navbar.position === 'left' && (
          <NavbarWrapperAdmin />
        )}

        <main
          id="fuse-main"
          className="flex flex-col flex-auto min-h-full min-w-0 relative z-10"
        >
          {config.toolbar.display && (
            <ToolbarAdmin
              className={config.toolbar.style === 'fixed' ? 'sticky top-0' : ''}
            />
          )}

          <div className="flex flex-col flex-auto min-h-0 relative z-10">
            <CustomDialog />

            <FuseSuspense>{useRoutes(routes as RouteObject[])}</FuseSuspense>

            {props.children}
          </div>

          {config.footer.display && (
            <FooterAdmin
              className={
                config.footer.style === 'fixed' ? 'sticky bottom-0' : ''
              }
            />
          )}
        </main>

        {config.navbar.display && config.navbar.position === 'right' && (
          <NavbarWrapperAdmin />
        )}
      </div>

      {config.rightSidePanel.display && <RightSideAdmin />}
      <FuseMessage />
    </Root>
  );
};

const Root = styled('div')(({ config }: { config: TLayoutConfig }) => ({
  ...(config.mode === 'boxed' && {
    clipPath: 'inset(0)',
    maxWidth: `${config.containerWidth}px`,
    margin: '0 auto',
    boxShadow:
      '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
  }),
  ...(config.mode === 'container' && {
    '& .container': {
      maxWidth: `${config.containerWidth}px`,
      width: '100%',
      margin: '0 auto',
    },
  }),
}));

export default memo(AdminLayout);
