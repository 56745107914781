import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The scalar DateTime represents a DateTime in UTC. */
  DateTime: { input: Date; output: Date; }
};

/** Represents an account in the system. Account is the information assigned to the Persona and extends the information about it. */
export type Account = AuditableEntity & {
  readonly __typename: 'Account';
  /** Account's created at date. */
  readonly createdAt: Scalars['DateTime']['output'];
  /** Account's location. */
  readonly location?: Maybe<Location>;
  /** Account's phone number. */
  readonly phone?: Maybe<Scalars['String']['output']>;
  /** Account's settings. */
  readonly settings?: Maybe<AccountSettings>;
  /** Account's status. */
  readonly status: AccountStatus;
  /** Account's updated at date. */
  readonly updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

/** Represents an account settings. */
export type AccountSettings = {
  readonly __typename: 'AccountSettings';
  /** Account's currency. */
  readonly currency?: Maybe<Scalars['String']['output']>;
  /** Account's language. */
  readonly language?: Maybe<Scalars['String']['output']>;
  /** UI Theme settings */
  readonly themeSettings?: Maybe<ThemeSettings>;
  /** The UI Color Theme. They are redefined in the system. */
  readonly themeType: Scalars['String']['output'];
  /** Account's timezone. */
  readonly timezone?: Maybe<Scalars['String']['output']>;
};

/** Represents an account status. */
export enum AccountStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Inactive = 'INACTIVE',
  Suspended = 'SUSPENDED'
}

/**
 * The concrete type of the user in the system. Represents system administrators,
 * which have access to the entire system (e.g. can manage other users in the system).
 */
export type Admin = Node & Persona & {
  readonly __typename: 'Admin';
  /** The account associated with the current Persona. */
  readonly account: Account;
  /** User's avatar URL. */
  readonly avatarUrl?: Maybe<Scalars['String']['output']>;
  /** User's display name. */
  readonly displayName: Scalars['String']['output'];
  /** User's email. */
  readonly email: Scalars['String']['output'];
  /** User's first name. */
  readonly firstName: Scalars['String']['output'];
  /** The id of the node. */
  readonly id: Scalars['ID']['output'];
  /** User's last name. */
  readonly lastName: Scalars['String']['output'];
};

/** The generic AuditableEntity interface, represents any entity in the system that can be audited. */
export type AuditableEntity = {
  /** The date and time when the entity was created. */
  readonly createdAt: Scalars['DateTime']['output'];
  /** The date and time when the entity was last updated. */
  readonly updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

/** Represents a category. */
export type Category = Node & {
  readonly __typename: 'Category';
  /** Category's description. */
  readonly description: Scalars['String']['output'];
  /** The id of the node. */
  readonly id: Scalars['ID']['output'];
  /** Category's name. */
  readonly name: Scalars['String']['output'];
};

/** Represents a category connection. */
export type CategoryConnection = {
  readonly __typename: 'CategoryConnection';
  /** The edges of the connection. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<CategoryEdge>>>;
  /** Shortcut to the node, if the only one expected. */
  readonly node?: Maybe<Category>;
  /** The page information. Cursor position, amount of items per page, etc. */
  readonly pageInfo: PageInfo;
};

/** Represents a category edge. */
export type CategoryEdge = {
  readonly __typename: 'CategoryEdge';
  /** The cursor for this result item fits naturally here */
  readonly cursor?: Maybe<Scalars['String']['output']>;
  /** "node" is a generalized name for an item in a collection */
  readonly node?: Maybe<Category>;
};

/** Represents a category filter input. */
export type CategoryFilterInput = {
  /** The category id. */
  readonly id?: InputMaybe<FieldFilterInput>;
  /** The product type. */
  readonly name?: InputMaybe<FieldFilterInput>;
};

/** Represents the error result of the change password mutation. */
export type ChangePasswordError = Error & {
  readonly __typename: 'ChangePasswordError';
  /** The error message. */
  readonly message: Scalars['String']['output'];
};

/** Input type for change password mutation. */
export type ChangePasswordInput = {
  /** The old password. */
  readonly oldPassword: Scalars['String']['input'];
  /** The new password. */
  readonly password: Scalars['String']['input'];
  /** The new password confirmation. */
  readonly passwordConfirmation: Scalars['String']['input'];
  /** The id of the account. */
  readonly personaId: Scalars['ID']['input'];
};

/** Represents the result of the change password mutation. */
export type ChangePasswordResult = ChangePasswordError | ChangePasswordSuccess;

/** Represents the success result of the change password mutation. */
export type ChangePasswordSuccess = {
  readonly __typename: 'ChangePasswordSuccess';
  /** The persona. */
  readonly persona: PersonaResult;
};

/** Represents a city. */
export type City = Node & {
  readonly __typename: 'City';
  /** The id of the node. */
  readonly id: Scalars['ID']['output'];
  /** City's name. */
  readonly name: Scalars['String']['output'];
};

/** Represents a city connection. */
export type CityConnection = {
  readonly __typename: 'CityConnection';
  /** The edges of the connection. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<CityEdge>>>;
  /** Shortcut to the node, if the only one expected. */
  readonly node?: Maybe<City>;
  /** The page information. Cursor position, amount of items per page, etc. */
  readonly pageInfo: PageInfo;
};

/** Represents a city edge. */
export type CityEdge = {
  readonly __typename: 'CityEdge';
  /** The cursor for this result item fits naturally here */
  readonly cursor?: Maybe<Scalars['String']['output']>;
  /** "node" is a generalized name for an item in a collection */
  readonly node?: Maybe<City>;
};

/** Represents a city filter input. */
export type CityFilterInput = {
  /** The city id. */
  readonly id?: InputMaybe<FieldFilterInput>;
  /** The city type. */
  readonly name?: InputMaybe<FieldFilterInput>;
};

/** Represents Consumer role. Consumers are consumers of the goods. */
export type Consumer = Node & Persona & {
  readonly __typename: 'Consumer';
  /** The account associated with the current Persona. */
  readonly account: Account;
  /** User's avatar URL. */
  readonly avatarUrl?: Maybe<Scalars['String']['output']>;
  /** User's display name. */
  readonly displayName: Scalars['String']['output'];
  /** User's email. */
  readonly email: Scalars['String']['output'];
  /** User's first name. */
  readonly firstName: Scalars['String']['output'];
  /** The id of the node. */
  readonly id: Scalars['ID']['output'];
  /** User's last name. */
  readonly lastName: Scalars['String']['output'];
};

/** Represents the error result of the delete product mutation. */
export type DeleteProductError = Error & {
  readonly __typename: 'DeleteProductError';
  /** The error message. */
  readonly message: Scalars['String']['output'];
};

/** Represents the result of the delete product mutation. */
export type DeleteProductResult = DeleteProductError | DeleteProductSuccess;

/** Represents the success result of the delete product mutation. */
export type DeleteProductSuccess = {
  readonly __typename: 'DeleteProductSuccess';
  /** The success message. */
  readonly message: Scalars['String']['output'];
};

/** Represents the error result of the delete recipe mutation. */
export type DeleteRecipeError = Error & {
  readonly __typename: 'DeleteRecipeError';
  /** The error message. */
  readonly message: Scalars['String']['output'];
};

/** Represents the result of the recipe product mutation. */
export type DeleteRecipeResult = DeleteRecipeError | DeleteRecipeSuccess;

/** Represents the success result of the delete recipe mutation. */
export type DeleteRecipeSuccess = {
  readonly __typename: 'DeleteRecipeSuccess';
  /** The success message. */
  readonly message: Scalars['String']['output'];
};

/** Represents a delivery. */
export type Delivery = Node & {
  readonly __typename: 'Delivery';
  /** The id of the node. */
  readonly id: Scalars['ID']['output'];
  /** Delivery's name. */
  readonly name: Scalars['String']['output'];
};

/** Represents a delivery connection. */
export type DeliveryConnection = {
  readonly __typename: 'DeliveryConnection';
  /** The edges of the connection. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<DeliveryEdge>>>;
  /** Shortcut to the node, if the only one expected. */
  readonly node?: Maybe<Delivery>;
  /** The page information. Cursor position, amount of items per page, etc. */
  readonly pageInfo: PageInfo;
};

/** Represents a delivery edge. */
export type DeliveryEdge = {
  readonly __typename: 'DeliveryEdge';
  /** The cursor for this result item fits naturally here */
  readonly cursor?: Maybe<Scalars['String']['output']>;
  /** "node" is a generalized name for an item in a collection */
  readonly node?: Maybe<Delivery>;
};

/** Represents a city filter input. */
export type DeliveryFilterInput = {
  /** The city id. */
  readonly id?: InputMaybe<FieldFilterInput>;
  /** The city type. */
  readonly name?: InputMaybe<FieldFilterInput>;
};

/** The generic Error interface, represents any error in the system. */
export type Error = {
  /** The error message. */
  readonly message: Scalars['String']['output'];
};

/** The generic filter input type, represents filter for any field. */
export type FieldFilterInput = {
  /** Represent the "equal" operation. */
  readonly eq?: InputMaybe<Scalars['String']['input']>;
  /** Represent the "in the set" operation. */
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  /** Represent the "not equal" operation. */
  readonly ne?: InputMaybe<Scalars['String']['input']>;
  /** Represent the "not in the set" operation. */
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  /** Represent the "regular expression" operation. */
  readonly regex?: InputMaybe<Scalars['String']['input']>;
};

/** Represents the error result of the forgot password mutation. */
export type ForgotPasswordError = Error & {
  readonly __typename: 'ForgotPasswordError';
  /** The error message. */
  readonly message: Scalars['String']['output'];
};

/** Represents an input for the forgot password mutation. */
export type ForgotPasswordInput = {
  /** The email of the user. */
  readonly email: Scalars['String']['input'];
  /** The role of the user. */
  readonly role: ProfileType;
};

/** Represents the success result of the forgot password mutation. */
export type ForgotPasswordResult = ForgotPasswordError | ForgotPasswordSuccess;

/** Represents the success result of the forgot password mutation. */
export type ForgotPasswordSuccess = {
  readonly __typename: 'ForgotPasswordSuccess';
  /** The success message. */
  readonly message: Scalars['String']['output'];
};

/** Represents an invoice. */
export type Invoice = {
  readonly __typename: 'Invoice';
  /** The invoice date. */
  readonly createdAt: Scalars['DateTime']['output'];
  /** The discount. */
  readonly discount: Scalars['Float']['output'];
  /** The invoice number. */
  readonly number: Scalars['String']['output'];
  /** The sub total. */
  readonly subtotal: Scalars['Float']['output'];
  /** The tax. */
  readonly tax: Scalars['Float']['output'];
  /** The total. */
  readonly total: Scalars['Float']['output'];
};

/** Represents a location. */
export type Location = {
  readonly __typename: 'Location';
  /** Account's address. */
  readonly address: Scalars['String']['output'];
  /** The city of the location. */
  readonly city: City;
  /** Account's country. */
  readonly country: Scalars['String']['output'];
  /** The latitude of the location. */
  readonly latitude?: Maybe<Scalars['Float']['output']>;
  /** The longitude of the location. */
  readonly longitude?: Maybe<Scalars['Float']['output']>;
  /** Account's postal code. */
  readonly postalCode: Scalars['String']['output'];
};

/** Represents the error result of the me query. */
export type MeError = Error & {
  readonly __typename: 'MeError';
  /** The error message. */
  readonly message: Scalars['String']['output'];
};

/** Represents the result of the me query. */
export type MeResult = MeError | MeSuccess;

/** Represents the success result of the me query. */
export type MeSuccess = {
  readonly __typename: 'MeSuccess';
  /** The persona. */
  readonly persona: PersonaResult;
};

/**
 * The concrete type of the user in the system. Represents system moderators,
 * which have limited access to the system (e.g. to moderate new products, comments, recipes, etc).
 */
export type Moderator = Node & Persona & {
  readonly __typename: 'Moderator';
  /** The account associated with the current Persona. */
  readonly account: Account;
  /** User's avatar URL. */
  readonly avatarUrl?: Maybe<Scalars['String']['output']>;
  /** User's display name. */
  readonly displayName: Scalars['String']['output'];
  /** User's email. */
  readonly email: Scalars['String']['output'];
  /** User's first name. */
  readonly firstName: Scalars['String']['output'];
  /** The id of the node. */
  readonly id: Scalars['ID']['output'];
  /** User's last name. */
  readonly lastName: Scalars['String']['output'];
};

export type Mutation = {
  readonly __typename: 'Mutation';
  readonly changePassword: ChangePasswordResult;
  /** Confirm the email. */
  readonly confirmMail: Scalars['Boolean']['output'];
  /** Delete a product by its ID. */
  readonly deleteProduct: DeleteProductResult;
  /** Delete a recipe by its ID. */
  readonly deleteRecipe: DeleteRecipeResult;
  /** Send request to remind the forgotten password. */
  readonly forgotPassword: ForgotPasswordResult;
  /** Reset the forgotten password. */
  readonly resetPassword: ResetPasswordResult;
  /** Save the color theme into user profile. */
  readonly saveColorTheme: Scalars['Boolean']['output'];
  /** Save the consumer profile. */
  readonly saveConsumerProfile: SaveConsumerProfileResult;
  /** Save the producer profile. */
  readonly saveProducerProfile: SaveProducerProfileResult;
  /** Save the product. */
  readonly saveProduct: SaveProductResult;
  /** Save the recipe. */
  readonly saveRecipe: SaveRecipeResult;
  /** Sign up the user. */
  readonly signUp: SignUpResult;
  readonly updateAvatar: Scalars['Boolean']['output'];
};


export type MutationChangePasswordArgs = {
  passwordInput: ChangePasswordInput;
};


export type MutationConfirmMailArgs = {
  token?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDeleteProductArgs = {
  productId: Scalars['ID']['input'];
};


export type MutationDeleteRecipeArgs = {
  recipeId: Scalars['ID']['input'];
};


export type MutationForgotPasswordArgs = {
  userInfo: ForgotPasswordInput;
};


export type MutationResetPasswordArgs = {
  userInfo: ResetPasswordInput;
};


export type MutationSaveColorThemeArgs = {
  themeType: Scalars['String']['input'];
};


export type MutationSaveConsumerProfileArgs = {
  consumerProfile: SaveConsumerProfileInput;
};


export type MutationSaveProducerProfileArgs = {
  producerProfile: SaveProducerProfileInput;
};


export type MutationSaveProductArgs = {
  productInput: SaveProductInput;
};


export type MutationSaveRecipeArgs = {
  recipeInput: SaveRecipeInput;
};


export type MutationSignUpArgs = {
  userInfo: SignUpUserInfoInput;
};


export type MutationUpdateAvatarArgs = {
  avatar: Scalars['String']['input'];
  personaId: Scalars['ID']['input'];
};

/** Represents an account UI Theme navbar position. */
export enum NavbarPosition {
  Left = 'LEFT',
  Right = 'RIGHT'
}

/** Represents an account UI Theme navbar settings. */
export type NavbarSettings = {
  readonly __typename: 'NavbarSettings';
  /** The navbar background color. */
  readonly backgroundColor?: Maybe<Scalars['String']['output']>;
  /** The navbar color. */
  readonly color?: Maybe<Scalars['String']['output']>;
  /** The navbar position. */
  readonly position: NavbarPosition;
};

/** The generic Node interface, represents any entity in the system. */
export type Node = {
  /** The id of the node. */
  readonly id: Scalars['ID']['output'];
};

/** Represents an order. */
export type Order = AuditableEntity & Node & {
  readonly __typename: 'Order';
  /** Order's billing address. */
  readonly billingAddress?: Maybe<Scalars['String']['output']>;
  /** The date and time when the entity was created. */
  readonly createdAt: Scalars['DateTime']['output'];
  /** Consumer who ordered the products. */
  readonly customer: Consumer;
  /** The id of the node. */
  readonly id: Scalars['ID']['output'];
  /** Invoice details. */
  readonly invoice: Invoice;
  /** Order's status. */
  readonly orderStatus: OrderStatusType;
  /** Represents how the status of the order has been changed and when. */
  readonly orderStatusChange: ReadonlyArray<OrderStatusChange>;
  /** Payment details. */
  readonly payment?: Maybe<Payment>;
  /** Producer who owns the products. */
  readonly producer: Producer;
  /** Products in this order. */
  readonly products: ReadonlyArray<OrderProducts>;
  /** Shipment details. */
  readonly shipment?: Maybe<Shipment>;
  /** Order's shipping address. */
  readonly shippingAddress: Scalars['String']['output'];
  /** Order's total price. */
  readonly totalPrice: Scalars['Float']['output'];
  /** The date and time when the entity was last updated. */
  readonly updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

/** Represents a order connection. */
export type OrderConnection = {
  readonly __typename: 'OrderConnection';
  /** The edges of the connection. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<OrderEdge>>>;
  /** Shortcut to the node, if the only one expected. */
  readonly node?: Maybe<Order>;
  /** The page information. Cursor position, amount of items per page, etc. */
  readonly pageInfo: PageInfo;
};

/** Represents an order edge. */
export type OrderEdge = {
  readonly __typename: 'OrderEdge';
  /** The cursor for this result item fits naturally here */
  readonly cursor?: Maybe<Scalars['String']['output']>;
  /** "node" is a generalized name for an item in a collection */
  readonly node?: Maybe<Order>;
};

/** Represents an order filter input. */
export type OrderFilterInput = {
  /** The customer who placed the order. */
  readonly customer?: InputMaybe<FieldFilterInput>;
  /** The order id. */
  readonly id?: InputMaybe<FieldFilterInput>;
  /** The order status. */
  readonly orderStatus?: InputMaybe<FieldFilterInput>;
};

/** Represents a product in an order. */
export type OrderProducts = {
  readonly __typename: 'OrderProducts';
  /** The price of the product. */
  readonly price: Scalars['Float']['output'];
  /** The product. */
  readonly product: Product;
  /** The quantity of the product. */
  readonly quantity: Scalars['Int']['output'];
};

/** Represents a change in the order status. */
export type OrderStatusChange = {
  readonly __typename: 'OrderStatusChange';
  /** The status of the order. */
  readonly status: OrderStatusType;
  /** The date and time when the status was changed. */
  readonly updatedAt: Scalars['DateTime']['output'];
};

/** Represents the type of an order status. */
export enum OrderStatusType {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Deleted = 'DELETED',
  Delivered = 'DELIVERED',
  Done = 'DONE',
  Draft = 'DRAFT',
  New = 'NEW',
  Ordered = 'ORDERED',
  Paid = 'PAID',
  Ready = 'READY',
  Shipped = 'SHIPPED'
}

/** The generic PageInfo type, represents any pagination information in the system. */
export type PageInfo = {
  readonly __typename: 'PageInfo';
  /** The current cursor end position. */
  readonly endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  readonly hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  readonly hasPreviousPage: Scalars['Boolean']['output'];
  /** The current cursor start position. */
  readonly startCursor?: Maybe<Scalars['String']['output']>;
};

/** Represents a payment. */
export type Payment = {
  readonly __typename: 'Payment';
  /** The payment transaction date. */
  readonly createdAt: Scalars['DateTime']['output'];
  /** The payment transaction amount. */
  readonly total: Scalars['Float']['output'];
  /** The payment transaction id. */
  readonly transactionId: Scalars['String']['output'];
  /** The payment transaction type. */
  readonly transactionType: TransactionType;
};

/** The generic Persona entity, represents any authenticated user in the system. */
export type Persona = {
  /** The account associated with the current Persona. */
  readonly account: Account;
  /** User's avatar URL. */
  readonly avatarUrl?: Maybe<Scalars['String']['output']>;
  /** User's display name. */
  readonly displayName: Scalars['String']['output'];
  /** User's email. */
  readonly email: Scalars['String']['output'];
  /** User's first name. */
  readonly firstName: Scalars['String']['output'];
  /** The id of the node. */
  readonly id: Scalars['ID']['output'];
  /** User's last name. */
  readonly lastName: Scalars['String']['output'];
};

/** Persona result type. TODO: add error type */
export type PersonaResult = Admin | Consumer | Moderator | Producer;

/** Represents Producer role. Producers are providers of the goods. */
export type Producer = Node & Persona & {
  readonly __typename: 'Producer';
  /** The account associated with the current Persona. */
  readonly account: Account;
  /** User's avatar URL. */
  readonly avatarUrl?: Maybe<Scalars['String']['output']>;
  /** The producer's delivery methods. */
  readonly delivery: ReadonlyArray<Delivery>;
  /** User's display name. */
  readonly displayName: Scalars['String']['output'];
  /** User's email. */
  readonly email: Scalars['String']['output'];
  /** User's first name. */
  readonly firstName: Scalars['String']['output'];
  /** The id of the node. */
  readonly id: Scalars['ID']['output'];
  /** User's last name. */
  readonly lastName: Scalars['String']['output'];
  /** The products provided by the producer. */
  readonly products: ProductConnection;
  /** The producer recipes. */
  readonly recipes: RecipeConnection;
  /** The producer sales. */
  readonly sales: OrderConnection;
};


/** Represents Producer role. Producers are providers of the goods. */
export type ProducerProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortInput>;
};


/** Represents Producer role. Producers are providers of the goods. */
export type ProducerRecipesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortInput>;
};


/** Represents Producer role. Producers are providers of the goods. */
export type ProducerSalesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortInput>;
};

/** Represents a product created and belonging to the Producer. */
export type Product = AuditableEntity & Node & {
  readonly __typename: 'Product';
  /** Product's images URL. */
  readonly additionalPhotos: ReadonlyArray<Scalars['String']['output']>;
  /** Product's category. */
  readonly category: Category;
  /** Product's created at date. */
  readonly createdAt: Scalars['DateTime']['output'];
  /** Product's currency. */
  readonly currency: Scalars['String']['output'];
  /** Product's depth. */
  readonly depth: Scalars['Float']['output'];
  /** Product's description. */
  readonly description: Scalars['String']['output'];
  /** Product's extraShippingFee. */
  readonly extraShippingFee: Scalars['Float']['output'];
  /** Product's height. */
  readonly height: Scalars['Float']['output'];
  /** The id of the node. */
  readonly id: Scalars['ID']['output'];
  /** Product's image URL. */
  readonly imageUrl: Scalars['String']['output'];
  /** Product's owner. */
  readonly owner: Producer;
  /** Product's price. */
  readonly price: Scalars['Float']['output'];
  /** Product's priceNoTax. */
  readonly priceNoTax: Scalars['Float']['output'];
  /** Product's priceWithTax. */
  readonly priceWithTax: Scalars['Float']['output'];
  /** Product's rating. */
  readonly rating?: Maybe<Rating>;
  /** Product's status. */
  readonly status: ProductStatus;
  /** Product's tax. */
  readonly tax: Scalars['Float']['output'];
  /** Product's title. */
  readonly title: Scalars['String']['output'];
  /** Product's type. */
  readonly type?: Maybe<Type>;
  /** Product's updated at date. */
  readonly updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Product's weight. */
  readonly weight: Scalars['Float']['output'];
  /** Product's width. */
  readonly width: Scalars['Float']['output'];
};

/** Represents a product connection. */
export type ProductConnection = {
  readonly __typename: 'ProductConnection';
  /** The edges of the connection. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<ProductEdge>>>;
  /** Shortcut to the node, if the only one expected. */
  readonly node?: Maybe<Product>;
  /** The page information. Cursor position, amount of items per page, etc. */
  readonly pageInfo: PageInfo;
};

/** Represents a product edge. */
export type ProductEdge = {
  readonly __typename: 'ProductEdge';
  /** The cursor for this result item fits naturally here */
  readonly cursor?: Maybe<Scalars['String']['output']>;
  /** "node" is a generalized name for an item in a collection */
  readonly node?: Maybe<Product>;
};

/** Represents a product filter input. */
export type ProductFilterInput = {
  /** The product category. */
  readonly category?: InputMaybe<FieldFilterInput>;
  /** The product city. */
  readonly city?: InputMaybe<FieldFilterInput>;
  /** The product delivery method. */
  readonly delivery?: InputMaybe<FieldFilterInput>;
  /** The product id. */
  readonly id?: InputMaybe<FieldFilterInput>;
  /** The search query. */
  readonly search?: InputMaybe<Scalars['String']['input']>;
  /** The product type. */
  readonly type?: InputMaybe<FieldFilterInput>;
};

/** Represents a product status. */
export enum ProductStatus {
  Deleted = 'DELETED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Suspended = 'SUSPENDED'
}

/**
 * Represents the user profile type.
 * Should be only used for the sign up mutation.
 */
export enum ProfileType {
  Consumer = 'CONSUMER',
  Producer = 'PRODUCER'
}

/** The root query type which gives access points into the data universe. */
export type Query = {
  readonly __typename: 'Query';
  /** Represents a product categories. */
  readonly categories: CategoryConnection;
  /** Represents a cities. */
  readonly cities: CityConnection;
  /** Represents a delivery. */
  readonly delivery: DeliveryConnection;
  /** Represents an authenticated user of different subtypes [Admin, Moderator, Producer, Consumer] */
  readonly me: MeResult;
  /**
   * Represents orders.
   * // TODO: Move orders to the user type
   */
  readonly orders: OrderConnection;
  /** Represents a products list. */
  readonly products: ProductConnection;
  /** Represents recipes */
  readonly recipes: RecipeConnection;
};


/** The root query type which gives access points into the data universe. */
export type QueryCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CategoryFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortInput>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CityFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortInput>;
};


/** The root query type which gives access points into the data universe. */
export type QueryDeliveryArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<DeliveryFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortInput>;
};


/** The root query type which gives access points into the data universe. */
export type QueryOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<OrderFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortInput>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProductFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortInput>;
};


/** The root query type which gives access points into the data universe. */
export type QueryRecipesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<RecipeFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortInput>;
};

/** Represents a rating. */
export type Rating = {
  readonly __typename: 'Rating';
  /** The average rating. */
  readonly averageRating: Scalars['Float']['output'];
  /** The number of people who rated the product. */
  readonly peopleRatedCount: Scalars['Int']['output'];
};

/** Represents recipe. */
export type Recipe = AuditableEntity & Node & {
  readonly __typename: 'Recipe';
  /** Set of recipe photos */
  readonly additionalPhotos: ReadonlyArray<Scalars['String']['output']>;
  /** The recipe's author. */
  readonly author: Persona;
  /** Recipe's category. */
  readonly category: Category;
  /** The date and time when the entity was created. */
  readonly createdAt: Scalars['DateTime']['output'];
  /** The recipe's description. */
  readonly description: Scalars['String']['output'];
  /** The recipe identifier. */
  readonly id: Scalars['ID']['output'];
  /** The recipe's main image Url */
  readonly imageUrl: Scalars['String']['output'];
  /** The recipe's rating. */
  readonly rating?: Maybe<Rating>;
  /** The recipe's title. */
  readonly title: Scalars['String']['output'];
  /** The date and time when the entity was last updated. */
  readonly updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

/** Represents a recipe connection. */
export type RecipeConnection = {
  readonly __typename: 'RecipeConnection';
  /** The edges of the connection. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<RecipeEdge>>>;
  /** Shortcut to the node, if the only one expected. */
  readonly node?: Maybe<Recipe>;
  /** The page information. Cursor position, amount of items per page, etc. */
  readonly pageInfo: PageInfo;
};

/** Represents an recipe edge. */
export type RecipeEdge = {
  readonly __typename: 'RecipeEdge';
  /** The cursor for this result item fits naturally here */
  readonly cursor?: Maybe<Scalars['String']['output']>;
  /** "node" is a generalized name for an item in a collection */
  readonly node?: Maybe<Recipe>;
};

/** Represents an recipe filter input. */
export type RecipeFilterInput = {
  /** The author of the recipe. */
  readonly author?: InputMaybe<FieldFilterInput>;
  /** The recipe id. */
  readonly id?: InputMaybe<FieldFilterInput>;
};

/** Represents the error result of the reset password mutation. */
export type ResetPasswordError = Error & {
  readonly __typename: 'ResetPasswordError';
  /** The error message. */
  readonly message: Scalars['String']['output'];
};

/** Represents an input for the reset password mutation. */
export type ResetPasswordInput = {
  /** The password of the user. */
  readonly password: Scalars['String']['input'];
  /** The password confirmation of the user. */
  readonly passwordConfirm: Scalars['String']['input'];
  /** The token of the user. */
  readonly token?: InputMaybe<Scalars['String']['input']>;
};

/** Represents the success result of the reset password mutation. */
export type ResetPasswordResult = ResetPasswordError | ResetPasswordSuccess;

/** Represents the success result of the reset password mutation. */
export type ResetPasswordSuccess = {
  readonly __typename: 'ResetPasswordSuccess';
  /** The success message. */
  readonly result: Scalars['Boolean']['output'];
};

/** Represents the error result of the save consumer profile mutation. */
export type SaveConsumerProfileError = Error & {
  readonly __typename: 'SaveConsumerProfileError';
  /** The error message. */
  readonly message: Scalars['String']['output'];
};

/** Input type for save consumer profile mutation. */
export type SaveConsumerProfileInput = {
  /** The avatar URL of the consumer. */
  readonly avatarUrl?: InputMaybe<Scalars['String']['input']>;
  /** The display name of the consumer. */
  readonly displayName: Scalars['String']['input'];
  /** The email of the consumer. */
  readonly email: Scalars['String']['input'];
  /** The first name of the consumer. */
  readonly firstName: Scalars['String']['input'];
  /** The last name of the consumer. */
  readonly lastName: Scalars['String']['input'];
  /** The phone number of the consumer. */
  readonly phone?: InputMaybe<Scalars['String']['input']>;
  /** The authenticated user id. */
  readonly userId: Scalars['ID']['input'];
};

/** Represents the result of the save consumer profile mutation. */
export type SaveConsumerProfileResult = SaveConsumerProfileError | SaveConsumerProfileSuccess;

/** Represents the success result of the save consumer profile mutation. */
export type SaveConsumerProfileSuccess = {
  readonly __typename: 'SaveConsumerProfileSuccess';
  /** The persona. */
  readonly persona: PersonaResult;
};

/** Represents the error result of the save producer profile mutation. */
export type SaveProducerProfileError = Error & {
  readonly __typename: 'SaveProducerProfileError';
  /** The error message. */
  readonly message: Scalars['String']['output'];
};

/** Input type for save producer profile mutation. */
export type SaveProducerProfileInput = {
  /** The avatar URL of the producer. */
  readonly avatarUrl?: InputMaybe<Scalars['String']['input']>;
  /** The display name of the producer. */
  readonly displayName: Scalars['String']['input'];
  /** The email of the producer. */
  readonly email: Scalars['String']['input'];
  /** The first name of the producer. */
  readonly firstName: Scalars['String']['input'];
  /** The last name of the producer. */
  readonly lastName: Scalars['String']['input'];
  /** The phone number of the producer. */
  readonly phone?: InputMaybe<Scalars['String']['input']>;
  /** The authenticated user id. */
  readonly userId: Scalars['ID']['input'];
};

/** Represents the result of the save producer profile mutation. */
export type SaveProducerProfileResult = SaveProducerProfileError | SaveProducerProfileSuccess;

/** Represents the success result of the save producer profile mutation. */
export type SaveProducerProfileSuccess = {
  readonly __typename: 'SaveProducerProfileSuccess';
  /** The persona. */
  readonly persona: PersonaResult;
};

/** Represents the error result of the save product mutation. */
export type SaveProductError = Error & {
  readonly __typename: 'SaveProductError';
  /** The error message. */
  readonly message: Scalars['String']['output'];
};

/** Represents a save product input. */
export type SaveProductInput = {
  /** Save Product's category. */
  readonly categoryId: Scalars['ID']['input'];
  /** Save Product's depth. */
  readonly depth: Scalars['Float']['input'];
  /** Save Product's description. */
  readonly description: Scalars['String']['input'];
  /** Save Product's extraShippingFee. */
  readonly extraShippingFee: Scalars['Float']['input'];
  /** Save Product's height. */
  readonly height: Scalars['Float']['input'];
  readonly id?: InputMaybe<Scalars['ID']['input']>;
  /** Save Product's image URL. */
  readonly imageUrl: Scalars['String']['input'];
  /** Save Product's price. */
  readonly price: Scalars['Float']['input'];
  /** Save Product's prive without tax. */
  readonly priceNoTax: Scalars['Float']['input'];
  /** Save Product's prive with tax. */
  readonly priceWithTax: Scalars['Float']['input'];
  /** Save Product's tax. */
  readonly tax: Scalars['Float']['input'];
  /** Save Product's title. */
  readonly title: Scalars['String']['input'];
  /** Save Product's weight. */
  readonly weight: Scalars['Float']['input'];
  /** Save Product's width. */
  readonly width: Scalars['Float']['input'];
};

/** Represents the success result of the save product mutation. */
export type SaveProductResult = SaveProductError | SaveProductSuccess;

/** Represents the success result of the save product mutation. */
export type SaveProductSuccess = {
  readonly __typename: 'SaveProductSuccess';
  /** The success message. */
  readonly product: Product;
};

/** Represents the error result of the save recipe mutation. */
export type SaveRecipeError = Error & {
  readonly __typename: 'SaveRecipeError';
  /** The error message. */
  readonly message: Scalars['String']['output'];
};

/** Represents a save recipe input. */
export type SaveRecipeInput = {
  /** Save recipes's category. */
  readonly categoryId: Scalars['ID']['input'];
  /** Save recipes's description. */
  readonly description: Scalars['String']['input'];
  readonly id?: InputMaybe<Scalars['ID']['input']>;
  /** Save recipes's main image Url */
  readonly imageUrl: Scalars['String']['input'];
  /** Save recipes's title. */
  readonly title: Scalars['String']['input'];
};

/** Represents the success result of the save recipe mutation. */
export type SaveRecipeResult = SaveRecipeError | SaveRecipeSuccess;

/** Represents the success result of the save recipe mutation. */
export type SaveRecipeSuccess = {
  readonly __typename: 'SaveRecipeSuccess';
  /** The success message. */
  readonly recipe: Recipe;
};

/** Details about shipment. */
export type Shipment = {
  readonly __typename: 'Shipment';
  /** Carrier which provides shipment. */
  readonly carrier: Scalars['String']['output'];
  /** The carrier fee. */
  readonly fee?: Maybe<Scalars['Float']['output']>;
  /** Date and time when the order was shipped. */
  readonly shippedAt: Scalars['DateTime']['output'];
  /** Tracking number. */
  readonly tracking: Scalars['Float']['output'];
  /** Weight of the products. */
  readonly weight: Scalars['Float']['output'];
};

/** Represents the error result of the sign up mutation. */
export type SignUpError = Error & {
  readonly __typename: 'SignUpError';
  /** The error message. */
  readonly message: Scalars['String']['output'];
};

/** Represents the result of the sign up mutation. */
export type SignUpResult = SignUpError | SignUpSuccess;

/** Represents the success result of the sign up mutation. */
export type SignUpSuccess = {
  readonly __typename: 'SignUpSuccess';
  /** The persona. */
  readonly persona: PersonaResult;
};

/** Represents an input for the sign up mutation. */
export type SignUpUserInfoInput = {
  /** The flag indicating that the user has accepted the terms and conditions. */
  readonly acceptTerms: Scalars['Boolean']['input'];
  /** The display name of the user. */
  readonly displayName: Scalars['String']['input'];
  /** The email of the user. */
  readonly email: Scalars['String']['input'];
  /** The password of the user. */
  readonly password: Scalars['String']['input'];
  /** The password confirmation of the user. */
  readonly passwordConfirmation: Scalars['String']['input'];
  /** The role of the user. */
  readonly role: ProfileType;
};

/** Represents sort direction */
export enum SortDirection {
  /** Ascending sort direction */
  Asc = 'ASC',
  /** Descending sort direction */
  Desc = 'DESC'
}

/** The generic sort input type, represents sort for any type. */
export type SortInput = {
  /** The sort direction. */
  readonly direction: SortDirection;
  /** The field to sort by. */
  readonly fields: ReadonlyArray<Scalars['String']['input']>;
};

/** Represents an account UI Theme settings. */
export type ThemeSettings = {
  readonly __typename: 'ThemeSettings';
  /** The navbar settings. */
  readonly navbar: NavbarSettings;
};

/** Type of transaction */
export enum TransactionType {
  BankTransfer = 'BANK_TRANSFER',
  Card = 'CARD',
  Cash = 'CASH'
}

/** Represents a type. */
export type Type = Node & {
  readonly __typename: 'Type';
  /** The id of the node. */
  readonly id: Scalars['ID']['output'];
  /** Type's name. */
  readonly name: Scalars['String']['output'];
};

export type OrderDetailsQuery_orders_OrderConnection_node_Order_customer_Consumer_account_Account_location_Location_city_City = { readonly __typename: 'City', readonly id: string, readonly name: string };

export type OrderDetailsQuery_orders_OrderConnection_node_Order_customer_Consumer_account_Account_location_Location = { readonly __typename: 'Location', readonly city: OrderDetailsQuery_orders_OrderConnection_node_Order_customer_Consumer_account_Account_location_Location_city_City };

export type OrderDetailsQuery_orders_OrderConnection_node_Order_customer_Consumer_account_Account = { readonly __typename: 'Account', readonly phone?: string | undefined, readonly location?: OrderDetailsQuery_orders_OrderConnection_node_Order_customer_Consumer_account_Account_location_Location | undefined };

export type OrderDetailsQuery_orders_OrderConnection_node_Order_customer_Consumer = { readonly __typename: 'Consumer', readonly displayName: string, readonly email: string, readonly avatarUrl?: string | undefined, readonly account: OrderDetailsQuery_orders_OrderConnection_node_Order_customer_Consumer_account_Account };

export type OrderDetailsQuery_orders_OrderConnection_node_Order_orderStatusChange_OrderStatusChange = { readonly __typename: 'OrderStatusChange', readonly status: OrderStatusType, readonly updatedAt: Date };

export type OrderDetailsQuery_orders_OrderConnection_node_Order_shipment_Shipment = { readonly __typename: 'Shipment', readonly tracking: number, readonly carrier: string, readonly weight: number, readonly fee?: number | undefined, readonly shippedAt: Date };

export type OrderDetailsQuery_orders_OrderConnection_node_Order_payment_Payment = { readonly __typename: 'Payment', readonly transactionId: string, readonly transactionType: TransactionType, readonly total: number, readonly createdAt: Date };

export type OrderDetailsQuery_orders_OrderConnection_node_Order = { readonly __typename: 'Order', readonly id: string, readonly createdAt: Date, readonly totalPrice: number, readonly orderStatus: OrderStatusType, readonly shippingAddress: string, readonly billingAddress?: string | undefined, readonly customer: OrderDetailsQuery_orders_OrderConnection_node_Order_customer_Consumer, readonly orderStatusChange: ReadonlyArray<OrderDetailsQuery_orders_OrderConnection_node_Order_orderStatusChange_OrderStatusChange>, readonly shipment?: OrderDetailsQuery_orders_OrderConnection_node_Order_shipment_Shipment | undefined, readonly payment?: OrderDetailsQuery_orders_OrderConnection_node_Order_payment_Payment | undefined };

export type OrderDetailsQuery_orders_OrderConnection = { readonly __typename: 'OrderConnection', readonly node?: OrderDetailsQuery_orders_OrderConnection_node_Order | undefined };

export type OrderDetailsQuery_Query = { readonly __typename: 'Query', readonly orders: OrderDetailsQuery_orders_OrderConnection };


export type OrderDetailsQueryVariables = Exact<{
  orderId: Scalars['String']['input'];
}>;


export type OrderDetailsQuery = OrderDetailsQuery_Query;

export type OrderInvoiceQuery_orders_OrderConnection_node_Order_customer_Consumer_account_Account_location_Location_city_City = { readonly __typename: 'City', readonly id: string, readonly name: string };

export type OrderInvoiceQuery_orders_OrderConnection_node_Order_customer_Consumer_account_Account_location_Location = { readonly __typename: 'Location', readonly city: OrderInvoiceQuery_orders_OrderConnection_node_Order_customer_Consumer_account_Account_location_Location_city_City };

export type OrderInvoiceQuery_orders_OrderConnection_node_Order_customer_Consumer_account_Account = { readonly __typename: 'Account', readonly phone?: string | undefined, readonly location?: OrderInvoiceQuery_orders_OrderConnection_node_Order_customer_Consumer_account_Account_location_Location | undefined };

export type OrderInvoiceQuery_orders_OrderConnection_node_Order_customer_Consumer = { readonly __typename: 'Consumer', readonly displayName: string, readonly email: string, readonly avatarUrl?: string | undefined, readonly account: OrderInvoiceQuery_orders_OrderConnection_node_Order_customer_Consumer_account_Account };

export type OrderInvoiceQuery_orders_OrderConnection_node_Order_producer_Producer_account_Account_location_Location_city_City = { readonly __typename: 'City', readonly id: string, readonly name: string };

export type OrderInvoiceQuery_orders_OrderConnection_node_Order_producer_Producer_account_Account_location_Location = { readonly __typename: 'Location', readonly city: OrderInvoiceQuery_orders_OrderConnection_node_Order_producer_Producer_account_Account_location_Location_city_City };

export type OrderInvoiceQuery_orders_OrderConnection_node_Order_producer_Producer_account_Account = { readonly __typename: 'Account', readonly phone?: string | undefined, readonly location?: OrderInvoiceQuery_orders_OrderConnection_node_Order_producer_Producer_account_Account_location_Location | undefined };

export type OrderInvoiceQuery_orders_OrderConnection_node_Order_producer_Producer = { readonly __typename: 'Producer', readonly id: string, readonly displayName: string, readonly email: string, readonly avatarUrl?: string | undefined, readonly account: OrderInvoiceQuery_orders_OrderConnection_node_Order_producer_Producer_account_Account };

export type OrderInvoiceQuery_orders_OrderConnection_node_Order_products_OrderProducts_product_Product = { readonly __typename: 'Product', readonly id: string, readonly title: string, readonly price: number, readonly imageUrl: string };

export type OrderInvoiceQuery_orders_OrderConnection_node_Order_products_OrderProducts = { readonly __typename: 'OrderProducts', readonly quantity: number, readonly product: OrderInvoiceQuery_orders_OrderConnection_node_Order_products_OrderProducts_product_Product };

export type OrderInvoiceQuery_orders_OrderConnection_node_Order_invoice_Invoice = { readonly __typename: 'Invoice', readonly createdAt: Date, readonly subtotal: number, readonly tax: number, readonly discount: number, readonly total: number };

export type OrderInvoiceQuery_orders_OrderConnection_node_Order = { readonly __typename: 'Order', readonly id: string, readonly shippingAddress: string, readonly billingAddress?: string | undefined, readonly customer: OrderInvoiceQuery_orders_OrderConnection_node_Order_customer_Consumer, readonly producer: OrderInvoiceQuery_orders_OrderConnection_node_Order_producer_Producer, readonly products: ReadonlyArray<OrderInvoiceQuery_orders_OrderConnection_node_Order_products_OrderProducts>, readonly invoice: OrderInvoiceQuery_orders_OrderConnection_node_Order_invoice_Invoice };

export type OrderInvoiceQuery_orders_OrderConnection = { readonly __typename: 'OrderConnection', readonly node?: OrderInvoiceQuery_orders_OrderConnection_node_Order | undefined };

export type OrderInvoiceQuery_Query = { readonly __typename: 'Query', readonly orders: OrderInvoiceQuery_orders_OrderConnection };


export type OrderInvoiceQueryVariables = Exact<{
  orderId: Scalars['String']['input'];
}>;


export type OrderInvoiceQuery = OrderInvoiceQuery_Query;

export type OrderProductsQuery_orders_OrderConnection_node_Order_products_OrderProducts_product_Product = { readonly __typename: 'Product', readonly id: string, readonly title: string, readonly price: number, readonly imageUrl: string };

export type OrderProductsQuery_orders_OrderConnection_node_Order_products_OrderProducts = { readonly __typename: 'OrderProducts', readonly quantity: number, readonly product: OrderProductsQuery_orders_OrderConnection_node_Order_products_OrderProducts_product_Product };

export type OrderProductsQuery_orders_OrderConnection_node_Order = { readonly __typename: 'Order', readonly id: string, readonly products: ReadonlyArray<OrderProductsQuery_orders_OrderConnection_node_Order_products_OrderProducts> };

export type OrderProductsQuery_orders_OrderConnection = { readonly __typename: 'OrderConnection', readonly node?: OrderProductsQuery_orders_OrderConnection_node_Order | undefined };

export type OrderProductsQuery_Query = { readonly __typename: 'Query', readonly orders: OrderProductsQuery_orders_OrderConnection };


export type OrderProductsQueryVariables = Exact<{
  orderId: Scalars['String']['input'];
}>;


export type OrderProductsQuery = OrderProductsQuery_Query;

export type OrdersQuery_orders_OrderConnection_edges_OrderEdge_node_Order_customer_Consumer_account_Account_location_Location_city_City = { readonly __typename: 'City', readonly id: string, readonly name: string };

export type OrdersQuery_orders_OrderConnection_edges_OrderEdge_node_Order_customer_Consumer_account_Account_location_Location = { readonly __typename: 'Location', readonly city: OrdersQuery_orders_OrderConnection_edges_OrderEdge_node_Order_customer_Consumer_account_Account_location_Location_city_City };

export type OrdersQuery_orders_OrderConnection_edges_OrderEdge_node_Order_customer_Consumer_account_Account = { readonly __typename: 'Account', readonly phone?: string | undefined, readonly location?: OrdersQuery_orders_OrderConnection_edges_OrderEdge_node_Order_customer_Consumer_account_Account_location_Location | undefined };

export type OrdersQuery_orders_OrderConnection_edges_OrderEdge_node_Order_customer_Consumer = { readonly __typename: 'Consumer', readonly displayName: string, readonly email: string, readonly avatarUrl?: string | undefined, readonly account: OrdersQuery_orders_OrderConnection_edges_OrderEdge_node_Order_customer_Consumer_account_Account };

export type OrdersQuery_orders_OrderConnection_edges_OrderEdge_node_Order = { readonly __typename: 'Order', readonly id: string, readonly createdAt: Date, readonly totalPrice: number, readonly customer: OrdersQuery_orders_OrderConnection_edges_OrderEdge_node_Order_customer_Consumer };

export type OrdersQuery_orders_OrderConnection_edges_OrderEdge = { readonly __typename: 'OrderEdge', readonly node?: OrdersQuery_orders_OrderConnection_edges_OrderEdge_node_Order | undefined };

export type OrdersQuery_orders_OrderConnection = { readonly __typename: 'OrderConnection', readonly edges?: ReadonlyArray<OrdersQuery_orders_OrderConnection_edges_OrderEdge | undefined> | undefined };

export type OrdersQuery_Query = { readonly __typename: 'Query', readonly orders: OrdersQuery_orders_OrderConnection };


export type OrdersQueryVariables = Exact<{
  orderStatus: Scalars['String']['input'];
}>;


export type OrdersQuery = OrdersQuery_Query;

export type CategoriesQuery_categories_CategoryConnection_edges_CategoryEdge_node_Category = { readonly __typename: 'Category', readonly id: string, readonly name: string };

export type CategoriesQuery_categories_CategoryConnection_edges_CategoryEdge = { readonly __typename: 'CategoryEdge', readonly node?: CategoriesQuery_categories_CategoryConnection_edges_CategoryEdge_node_Category | undefined };

export type CategoriesQuery_categories_CategoryConnection = { readonly __typename: 'CategoryConnection', readonly edges?: ReadonlyArray<CategoriesQuery_categories_CategoryConnection_edges_CategoryEdge | undefined> | undefined };

export type CategoriesQuery_Query = { readonly __typename: 'Query', readonly categories: CategoriesQuery_categories_CategoryConnection };


export type CategoriesQueryVariables = Exact<{
  filter?: InputMaybe<CategoryFilterInput>;
  sort?: InputMaybe<SortInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type CategoriesQuery = CategoriesQuery_Query;

export type DeleteProductMutation_deleteProduct_DeleteProductError = { readonly __typename: 'DeleteProductError', readonly message: string };

export type DeleteProductMutation_deleteProduct_DeleteProductSuccess = { readonly __typename: 'DeleteProductSuccess', readonly message: string };

export type DeleteProductMutation_deleteProduct = DeleteProductMutation_deleteProduct_DeleteProductError | DeleteProductMutation_deleteProduct_DeleteProductSuccess;

export type DeleteProductMutation_Mutation = { readonly __typename: 'Mutation', readonly deleteProduct: DeleteProductMutation_deleteProduct };


export type DeleteProductMutationVariables = Exact<{
  productId: Scalars['ID']['input'];
}>;


export type DeleteProductMutation = DeleteProductMutation_Mutation;

export type MyProductsQuery_me_MeSuccess_persona_Producer_products_ProductConnection_edges_ProductEdge_node_Product_category_Category = { readonly __typename: 'Category', readonly name: string };

export type MyProductsQuery_me_MeSuccess_persona_Producer_products_ProductConnection_edges_ProductEdge_node_Product_rating_Rating = { readonly __typename: 'Rating', readonly averageRating: number };

export type MyProductsQuery_me_MeSuccess_persona_Producer_products_ProductConnection_edges_ProductEdge_node_Product = { readonly __typename: 'Product', readonly id: string, readonly price: number, readonly title: string, readonly imageUrl: string, readonly createdAt: Date, readonly category: MyProductsQuery_me_MeSuccess_persona_Producer_products_ProductConnection_edges_ProductEdge_node_Product_category_Category, readonly rating?: MyProductsQuery_me_MeSuccess_persona_Producer_products_ProductConnection_edges_ProductEdge_node_Product_rating_Rating | undefined };

export type MyProductsQuery_me_MeSuccess_persona_Producer_products_ProductConnection_edges_ProductEdge = { readonly __typename: 'ProductEdge', readonly node?: MyProductsQuery_me_MeSuccess_persona_Producer_products_ProductConnection_edges_ProductEdge_node_Product | undefined };

export type MyProductsQuery_me_MeSuccess_persona_Producer_products_ProductConnection = { readonly __typename: 'ProductConnection', readonly edges?: ReadonlyArray<MyProductsQuery_me_MeSuccess_persona_Producer_products_ProductConnection_edges_ProductEdge | undefined> | undefined };

export type MyProductsQuery_me_MeSuccess_persona_Admin = { readonly __typename: 'Admin' };

export type MyProductsQuery_me_MeSuccess_persona_Consumer = { readonly __typename: 'Consumer' };

export type MyProductsQuery_me_MeSuccess_persona_Moderator = { readonly __typename: 'Moderator' };

export type MyProductsQuery_me_MeSuccess_persona_Producer = { readonly __typename: 'Producer', readonly products: MyProductsQuery_me_MeSuccess_persona_Producer_products_ProductConnection };

export type MyProductsQuery_me_MeSuccess_persona = MyProductsQuery_me_MeSuccess_persona_Admin | MyProductsQuery_me_MeSuccess_persona_Consumer | MyProductsQuery_me_MeSuccess_persona_Moderator | MyProductsQuery_me_MeSuccess_persona_Producer;

export type MyProductsQuery_me_MeError = { readonly __typename: 'MeError' };

export type MyProductsQuery_me_MeSuccess = { readonly __typename: 'MeSuccess', readonly persona: MyProductsQuery_me_MeSuccess_persona };

export type MyProductsQuery_me = MyProductsQuery_me_MeError | MyProductsQuery_me_MeSuccess;

export type MyProductsQuery_Query = { readonly __typename: 'Query', readonly me: MyProductsQuery_me };


export type MyProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyProductsQuery = MyProductsQuery_Query;

export type ProductQuery_products_ProductConnection_node_Product_category_Category = { readonly __typename: 'Category', readonly id: string, readonly name: string };

export type ProductQuery_products_ProductConnection_node_Product = { readonly __typename: 'Product', readonly title: string, readonly description: string, readonly imageUrl: string, readonly price: number, readonly depth: number, readonly extraShippingFee: number, readonly height: number, readonly weight: number, readonly width: number, readonly tax: number, readonly priceNoTax: number, readonly priceWithTax: number, readonly category: ProductQuery_products_ProductConnection_node_Product_category_Category };

export type ProductQuery_products_ProductConnection = { readonly __typename: 'ProductConnection', readonly node?: ProductQuery_products_ProductConnection_node_Product | undefined };

export type ProductQuery_Query = { readonly __typename: 'Query', readonly products: ProductQuery_products_ProductConnection };


export type ProductQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type ProductQuery = ProductQuery_Query;

export type SaveProductMutation_saveProduct_SaveProductSuccess_product_Product_category_Category = { readonly __typename: 'Category', readonly id: string, readonly name: string };

export type SaveProductMutation_saveProduct_SaveProductSuccess_product_Product = { readonly __typename: 'Product', readonly id: string, readonly title: string, readonly description: string, readonly price: number, readonly imageUrl: string, readonly depth: number, readonly extraShippingFee: number, readonly height: number, readonly weight: number, readonly width: number, readonly tax: number, readonly priceNoTax: number, readonly priceWithTax: number, readonly category: SaveProductMutation_saveProduct_SaveProductSuccess_product_Product_category_Category };

export type SaveProductMutation_saveProduct_SaveProductError = { readonly __typename: 'SaveProductError', readonly message: string };

export type SaveProductMutation_saveProduct_SaveProductSuccess = { readonly __typename: 'SaveProductSuccess', readonly product: SaveProductMutation_saveProduct_SaveProductSuccess_product_Product };

export type SaveProductMutation_saveProduct = SaveProductMutation_saveProduct_SaveProductError | SaveProductMutation_saveProduct_SaveProductSuccess;

export type SaveProductMutation_Mutation = { readonly __typename: 'Mutation', readonly saveProduct: SaveProductMutation_saveProduct };


export type SaveProductMutationVariables = Exact<{
  productInput: SaveProductInput;
}>;


export type SaveProductMutation = SaveProductMutation_Mutation;

export type UpdateProductMutation_saveProduct_SaveProductSuccess_product_Product_category_Category = { readonly __typename: 'Category', readonly id: string, readonly name: string };

export type UpdateProductMutation_saveProduct_SaveProductSuccess_product_Product = { readonly __typename: 'Product', readonly id: string, readonly title: string, readonly description: string, readonly price: number, readonly imageUrl: string, readonly depth: number, readonly extraShippingFee: number, readonly height: number, readonly weight: number, readonly width: number, readonly tax: number, readonly priceNoTax: number, readonly priceWithTax: number, readonly category: UpdateProductMutation_saveProduct_SaveProductSuccess_product_Product_category_Category };

export type UpdateProductMutation_saveProduct_SaveProductError = { readonly __typename: 'SaveProductError', readonly message: string };

export type UpdateProductMutation_saveProduct_SaveProductSuccess = { readonly __typename: 'SaveProductSuccess', readonly product: UpdateProductMutation_saveProduct_SaveProductSuccess_product_Product };

export type UpdateProductMutation_saveProduct = UpdateProductMutation_saveProduct_SaveProductError | UpdateProductMutation_saveProduct_SaveProductSuccess;

export type UpdateProductMutation_Mutation = { readonly __typename: 'Mutation', readonly saveProduct: UpdateProductMutation_saveProduct };


export type UpdateProductMutationVariables = Exact<{
  productInput: SaveProductInput;
}>;


export type UpdateProductMutation = UpdateProductMutation_Mutation;

export type DeleteRecipeMutation_deleteRecipe_DeleteRecipeError = { readonly __typename: 'DeleteRecipeError', readonly message: string };

export type DeleteRecipeMutation_deleteRecipe_DeleteRecipeSuccess = { readonly __typename: 'DeleteRecipeSuccess', readonly message: string };

export type DeleteRecipeMutation_deleteRecipe = DeleteRecipeMutation_deleteRecipe_DeleteRecipeError | DeleteRecipeMutation_deleteRecipe_DeleteRecipeSuccess;

export type DeleteRecipeMutation_Mutation = { readonly __typename: 'Mutation', readonly deleteRecipe: DeleteRecipeMutation_deleteRecipe };


export type DeleteRecipeMutationVariables = Exact<{
  recipeId: Scalars['ID']['input'];
}>;


export type DeleteRecipeMutation = DeleteRecipeMutation_Mutation;

export type MyRecipesQuery_me_MeSuccess_persona_Producer_recipes_RecipeConnection_edges_RecipeEdge_node_Recipe_rating_Rating = { readonly __typename: 'Rating', readonly averageRating: number };

export type MyRecipesQuery_me_MeSuccess_persona_Producer_recipes_RecipeConnection_edges_RecipeEdge_node_Recipe = { readonly __typename: 'Recipe', readonly id: string, readonly title: string, readonly imageUrl: string, readonly rating?: MyRecipesQuery_me_MeSuccess_persona_Producer_recipes_RecipeConnection_edges_RecipeEdge_node_Recipe_rating_Rating | undefined };

export type MyRecipesQuery_me_MeSuccess_persona_Producer_recipes_RecipeConnection_edges_RecipeEdge = { readonly __typename: 'RecipeEdge', readonly node?: MyRecipesQuery_me_MeSuccess_persona_Producer_recipes_RecipeConnection_edges_RecipeEdge_node_Recipe | undefined };

export type MyRecipesQuery_me_MeSuccess_persona_Producer_recipes_RecipeConnection = { readonly __typename: 'RecipeConnection', readonly edges?: ReadonlyArray<MyRecipesQuery_me_MeSuccess_persona_Producer_recipes_RecipeConnection_edges_RecipeEdge | undefined> | undefined };

export type MyRecipesQuery_me_MeSuccess_persona_Admin = { readonly __typename: 'Admin' };

export type MyRecipesQuery_me_MeSuccess_persona_Consumer = { readonly __typename: 'Consumer' };

export type MyRecipesQuery_me_MeSuccess_persona_Moderator = { readonly __typename: 'Moderator' };

export type MyRecipesQuery_me_MeSuccess_persona_Producer = { readonly __typename: 'Producer', readonly recipes: MyRecipesQuery_me_MeSuccess_persona_Producer_recipes_RecipeConnection };

export type MyRecipesQuery_me_MeSuccess_persona = MyRecipesQuery_me_MeSuccess_persona_Admin | MyRecipesQuery_me_MeSuccess_persona_Consumer | MyRecipesQuery_me_MeSuccess_persona_Moderator | MyRecipesQuery_me_MeSuccess_persona_Producer;

export type MyRecipesQuery_me_MeError = { readonly __typename: 'MeError' };

export type MyRecipesQuery_me_MeSuccess = { readonly __typename: 'MeSuccess', readonly persona: MyRecipesQuery_me_MeSuccess_persona };

export type MyRecipesQuery_me = MyRecipesQuery_me_MeError | MyRecipesQuery_me_MeSuccess;

export type MyRecipesQuery_Query = { readonly __typename: 'Query', readonly me: MyRecipesQuery_me };


export type MyRecipesQueryVariables = Exact<{ [key: string]: never; }>;


export type MyRecipesQuery = MyRecipesQuery_Query;

export type RecipeQuery_recipes_RecipeConnection_node_Recipe_author_Admin = { readonly __typename: 'Admin', readonly firstName: string, readonly lastName: string };

export type RecipeQuery_recipes_RecipeConnection_node_Recipe_author_Consumer = { readonly __typename: 'Consumer', readonly firstName: string, readonly lastName: string };

export type RecipeQuery_recipes_RecipeConnection_node_Recipe_author_Moderator = { readonly __typename: 'Moderator', readonly firstName: string, readonly lastName: string };

export type RecipeQuery_recipes_RecipeConnection_node_Recipe_author_Producer = { readonly __typename: 'Producer', readonly firstName: string, readonly lastName: string };

export type RecipeQuery_recipes_RecipeConnection_node_Recipe_author = RecipeQuery_recipes_RecipeConnection_node_Recipe_author_Admin | RecipeQuery_recipes_RecipeConnection_node_Recipe_author_Consumer | RecipeQuery_recipes_RecipeConnection_node_Recipe_author_Moderator | RecipeQuery_recipes_RecipeConnection_node_Recipe_author_Producer;

export type RecipeQuery_recipes_RecipeConnection_node_Recipe_category_Category = { readonly __typename: 'Category', readonly id: string, readonly name: string };

export type RecipeQuery_recipes_RecipeConnection_node_Recipe = { readonly __typename: 'Recipe', readonly additionalPhotos: ReadonlyArray<string>, readonly title: string, readonly description: string, readonly createdAt: Date, readonly imageUrl: string, readonly author: RecipeQuery_recipes_RecipeConnection_node_Recipe_author, readonly category: RecipeQuery_recipes_RecipeConnection_node_Recipe_category_Category };

export type RecipeQuery_recipes_RecipeConnection = { readonly __typename: 'RecipeConnection', readonly node?: RecipeQuery_recipes_RecipeConnection_node_Recipe | undefined };

export type RecipeQuery_Query = { readonly __typename: 'Query', readonly recipes: RecipeQuery_recipes_RecipeConnection };


export type RecipeQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type RecipeQuery = RecipeQuery_Query;

export type SaveRecipeMutation_saveRecipe_SaveRecipeSuccess_recipe_Recipe_category_Category = { readonly __typename: 'Category', readonly description: string, readonly id: string, readonly name: string };

export type SaveRecipeMutation_saveRecipe_SaveRecipeSuccess_recipe_Recipe = { readonly __typename: 'Recipe', readonly id: string, readonly imageUrl: string, readonly title: string, readonly description: string, readonly category: SaveRecipeMutation_saveRecipe_SaveRecipeSuccess_recipe_Recipe_category_Category };

export type SaveRecipeMutation_saveRecipe_SaveRecipeError = { readonly __typename: 'SaveRecipeError', readonly message: string };

export type SaveRecipeMutation_saveRecipe_SaveRecipeSuccess = { readonly __typename: 'SaveRecipeSuccess', readonly recipe: SaveRecipeMutation_saveRecipe_SaveRecipeSuccess_recipe_Recipe };

export type SaveRecipeMutation_saveRecipe = SaveRecipeMutation_saveRecipe_SaveRecipeError | SaveRecipeMutation_saveRecipe_SaveRecipeSuccess;

export type SaveRecipeMutation_Mutation = { readonly __typename: 'Mutation', readonly saveRecipe: SaveRecipeMutation_saveRecipe };


export type SaveRecipeMutationVariables = Exact<{
  recipeInput: SaveRecipeInput;
}>;


export type SaveRecipeMutation = SaveRecipeMutation_Mutation;

export type UpdateRecipeMutation_saveRecipe_SaveRecipeSuccess_recipe_Recipe_category_Category = { readonly __typename: 'Category', readonly description: string, readonly id: string, readonly name: string };

export type UpdateRecipeMutation_saveRecipe_SaveRecipeSuccess_recipe_Recipe = { readonly __typename: 'Recipe', readonly id: string, readonly imageUrl: string, readonly title: string, readonly description: string, readonly category: UpdateRecipeMutation_saveRecipe_SaveRecipeSuccess_recipe_Recipe_category_Category };

export type UpdateRecipeMutation_saveRecipe_SaveRecipeError = { readonly __typename: 'SaveRecipeError', readonly message: string };

export type UpdateRecipeMutation_saveRecipe_SaveRecipeSuccess = { readonly __typename: 'SaveRecipeSuccess', readonly recipe: UpdateRecipeMutation_saveRecipe_SaveRecipeSuccess_recipe_Recipe };

export type UpdateRecipeMutation_saveRecipe = UpdateRecipeMutation_saveRecipe_SaveRecipeError | UpdateRecipeMutation_saveRecipe_SaveRecipeSuccess;

export type UpdateRecipeMutation_Mutation = { readonly __typename: 'Mutation', readonly saveRecipe: UpdateRecipeMutation_saveRecipe };


export type UpdateRecipeMutationVariables = Exact<{
  recipeInput: SaveRecipeInput;
}>;


export type UpdateRecipeMutation = UpdateRecipeMutation_Mutation;

export type MySalesQuery_me_MeSuccess_persona_Producer_sales_OrderConnection_edges_OrderEdge_node_Order_customer_Consumer = { readonly __typename: 'Consumer', readonly id: string, readonly displayName: string };

export type MySalesQuery_me_MeSuccess_persona_Producer_sales_OrderConnection_edges_OrderEdge_node_Order = { readonly __typename: 'Order', readonly id: string, readonly createdAt: Date, readonly totalPrice: number, readonly customer: MySalesQuery_me_MeSuccess_persona_Producer_sales_OrderConnection_edges_OrderEdge_node_Order_customer_Consumer };

export type MySalesQuery_me_MeSuccess_persona_Producer_sales_OrderConnection_edges_OrderEdge = { readonly __typename: 'OrderEdge', readonly node?: MySalesQuery_me_MeSuccess_persona_Producer_sales_OrderConnection_edges_OrderEdge_node_Order | undefined };

export type MySalesQuery_me_MeSuccess_persona_Producer_sales_OrderConnection = { readonly __typename: 'OrderConnection', readonly edges?: ReadonlyArray<MySalesQuery_me_MeSuccess_persona_Producer_sales_OrderConnection_edges_OrderEdge | undefined> | undefined };

export type MySalesQuery_me_MeSuccess_persona_Admin = { readonly __typename: 'Admin' };

export type MySalesQuery_me_MeSuccess_persona_Consumer = { readonly __typename: 'Consumer' };

export type MySalesQuery_me_MeSuccess_persona_Moderator = { readonly __typename: 'Moderator' };

export type MySalesQuery_me_MeSuccess_persona_Producer = { readonly __typename: 'Producer', readonly sales: MySalesQuery_me_MeSuccess_persona_Producer_sales_OrderConnection };

export type MySalesQuery_me_MeSuccess_persona = MySalesQuery_me_MeSuccess_persona_Admin | MySalesQuery_me_MeSuccess_persona_Consumer | MySalesQuery_me_MeSuccess_persona_Moderator | MySalesQuery_me_MeSuccess_persona_Producer;

export type MySalesQuery_me_MeError = { readonly __typename: 'MeError' };

export type MySalesQuery_me_MeSuccess = { readonly __typename: 'MeSuccess', readonly persona: MySalesQuery_me_MeSuccess_persona };

export type MySalesQuery_me = MySalesQuery_me_MeError | MySalesQuery_me_MeSuccess;

export type MySalesQuery_Query = { readonly __typename: 'Query', readonly me: MySalesQuery_me };


export type MySalesQueryVariables = Exact<{ [key: string]: never; }>;


export type MySalesQuery = MySalesQuery_Query;

export type ProductDetailsQuery_products_ProductConnection_node_Product_owner_Producer_delivery_Delivery = { readonly __typename: 'Delivery', readonly id: string, readonly name: string };

export type ProductDetailsQuery_products_ProductConnection_node_Product_owner_Producer_account_Account_location_Location_city_City = { readonly __typename: 'City', readonly id: string, readonly name: string };

export type ProductDetailsQuery_products_ProductConnection_node_Product_owner_Producer_account_Account_location_Location = { readonly __typename: 'Location', readonly city: ProductDetailsQuery_products_ProductConnection_node_Product_owner_Producer_account_Account_location_Location_city_City };

export type ProductDetailsQuery_products_ProductConnection_node_Product_owner_Producer_account_Account = { readonly __typename: 'Account', readonly location?: ProductDetailsQuery_products_ProductConnection_node_Product_owner_Producer_account_Account_location_Location | undefined };

export type ProductDetailsQuery_products_ProductConnection_node_Product_owner_Producer = { readonly __typename: 'Producer', readonly firstName: string, readonly delivery: ReadonlyArray<ProductDetailsQuery_products_ProductConnection_node_Product_owner_Producer_delivery_Delivery>, readonly account: ProductDetailsQuery_products_ProductConnection_node_Product_owner_Producer_account_Account };

export type ProductDetailsQuery_products_ProductConnection_node_Product_rating_Rating = { readonly __typename: 'Rating', readonly averageRating: number };

export type ProductDetailsQuery_products_ProductConnection_node_Product_category_Category = { readonly __typename: 'Category', readonly id: string, readonly name: string };

export type ProductDetailsQuery_products_ProductConnection_node_Product = { readonly __typename: 'Product', readonly title: string, readonly id: string, readonly description: string, readonly imageUrl: string, readonly additionalPhotos: ReadonlyArray<string>, readonly owner: ProductDetailsQuery_products_ProductConnection_node_Product_owner_Producer, readonly rating?: ProductDetailsQuery_products_ProductConnection_node_Product_rating_Rating | undefined, readonly category: ProductDetailsQuery_products_ProductConnection_node_Product_category_Category };

export type ProductDetailsQuery_products_ProductConnection = { readonly __typename: 'ProductConnection', readonly node?: ProductDetailsQuery_products_ProductConnection_node_Product | undefined };

export type ProductDetailsQuery_Query = { readonly __typename: 'Query', readonly products: ProductDetailsQuery_products_ProductConnection };


export type ProductDetailsQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type ProductDetailsQuery = ProductDetailsQuery_Query;

export type ProductsByTypeQuery_products_ProductConnection_edges_ProductEdge_node_Product_category_Category = { readonly __typename: 'Category', readonly name: string };

export type ProductsByTypeQuery_products_ProductConnection_edges_ProductEdge_node_Product_rating_Rating = { readonly __typename: 'Rating', readonly averageRating: number };

export type ProductsByTypeQuery_products_ProductConnection_edges_ProductEdge_node_Product_owner_Producer = { readonly __typename: 'Producer', readonly displayName: string };

export type ProductsByTypeQuery_products_ProductConnection_edges_ProductEdge_node_Product = { readonly __typename: 'Product', readonly id: string, readonly title: string, readonly imageUrl: string, readonly category: ProductsByTypeQuery_products_ProductConnection_edges_ProductEdge_node_Product_category_Category, readonly rating?: ProductsByTypeQuery_products_ProductConnection_edges_ProductEdge_node_Product_rating_Rating | undefined, readonly owner: ProductsByTypeQuery_products_ProductConnection_edges_ProductEdge_node_Product_owner_Producer };

export type ProductsByTypeQuery_products_ProductConnection_edges_ProductEdge = { readonly __typename: 'ProductEdge', readonly node?: ProductsByTypeQuery_products_ProductConnection_edges_ProductEdge_node_Product | undefined };

export type ProductsByTypeQuery_products_ProductConnection = { readonly __typename: 'ProductConnection', readonly edges?: ReadonlyArray<ProductsByTypeQuery_products_ProductConnection_edges_ProductEdge | undefined> | undefined };

export type ProductsByTypeQuery_Query = { readonly __typename: 'Query', readonly products: ProductsByTypeQuery_products_ProductConnection };


export type ProductsByTypeQueryVariables = Exact<{
  productType: Scalars['String']['input'];
}>;


export type ProductsByTypeQuery = ProductsByTypeQuery_Query;

export type ChangePasswordMutation_changePassword_ChangePasswordSuccess_persona_Admin = { readonly __typename: 'Admin', readonly id: string, readonly email: string, readonly firstName: string, readonly lastName: string, readonly displayName: string };

export type ChangePasswordMutation_changePassword_ChangePasswordSuccess_persona_Consumer = { readonly __typename: 'Consumer', readonly id: string, readonly email: string, readonly firstName: string, readonly lastName: string, readonly displayName: string };

export type ChangePasswordMutation_changePassword_ChangePasswordSuccess_persona_Moderator = { readonly __typename: 'Moderator', readonly id: string, readonly email: string, readonly firstName: string, readonly lastName: string, readonly displayName: string };

export type ChangePasswordMutation_changePassword_ChangePasswordSuccess_persona_Producer = { readonly __typename: 'Producer', readonly id: string, readonly email: string, readonly firstName: string, readonly lastName: string, readonly displayName: string };

export type ChangePasswordMutation_changePassword_ChangePasswordSuccess_persona = ChangePasswordMutation_changePassword_ChangePasswordSuccess_persona_Admin | ChangePasswordMutation_changePassword_ChangePasswordSuccess_persona_Consumer | ChangePasswordMutation_changePassword_ChangePasswordSuccess_persona_Moderator | ChangePasswordMutation_changePassword_ChangePasswordSuccess_persona_Producer;

export type ChangePasswordMutation_changePassword_ChangePasswordError = { readonly __typename: 'ChangePasswordError', readonly message: string };

export type ChangePasswordMutation_changePassword_ChangePasswordSuccess = { readonly __typename: 'ChangePasswordSuccess', readonly persona: ChangePasswordMutation_changePassword_ChangePasswordSuccess_persona };

export type ChangePasswordMutation_changePassword = ChangePasswordMutation_changePassword_ChangePasswordError | ChangePasswordMutation_changePassword_ChangePasswordSuccess;

export type ChangePasswordMutation_Mutation = { readonly __typename: 'Mutation', readonly changePassword: ChangePasswordMutation_changePassword };


export type ChangePasswordMutationVariables = Exact<{
  passwordInput: ChangePasswordInput;
}>;


export type ChangePasswordMutation = ChangePasswordMutation_Mutation;

export type MyProfileQuery_me_MeSuccess_persona_Admin_account_Account = { readonly __typename: 'Account', readonly phone?: string | undefined };

export type MyProfileQuery_me_MeSuccess_persona_Admin = { readonly __typename: 'Admin', readonly id: string, readonly displayName: string, readonly email: string, readonly avatarUrl?: string | undefined, readonly firstName: string, readonly lastName: string, readonly account: MyProfileQuery_me_MeSuccess_persona_Admin_account_Account };

export type MyProfileQuery_me_MeSuccess_persona_Consumer = { readonly __typename: 'Consumer', readonly id: string, readonly displayName: string, readonly email: string, readonly avatarUrl?: string | undefined, readonly firstName: string, readonly lastName: string, readonly account: MyProfileQuery_me_MeSuccess_persona_Admin_account_Account };

export type MyProfileQuery_me_MeSuccess_persona_Moderator = { readonly __typename: 'Moderator', readonly id: string, readonly displayName: string, readonly email: string, readonly avatarUrl?: string | undefined, readonly firstName: string, readonly lastName: string, readonly account: MyProfileQuery_me_MeSuccess_persona_Admin_account_Account };

export type MyProfileQuery_me_MeSuccess_persona_Producer = { readonly __typename: 'Producer', readonly id: string, readonly displayName: string, readonly email: string, readonly avatarUrl?: string | undefined, readonly firstName: string, readonly lastName: string, readonly account: MyProfileQuery_me_MeSuccess_persona_Admin_account_Account };

export type MyProfileQuery_me_MeSuccess_persona = MyProfileQuery_me_MeSuccess_persona_Admin | MyProfileQuery_me_MeSuccess_persona_Consumer | MyProfileQuery_me_MeSuccess_persona_Moderator | MyProfileQuery_me_MeSuccess_persona_Producer;

export type MyProfileQuery_me_MeError = { readonly __typename: 'MeError', readonly message: string };

export type MyProfileQuery_me_MeSuccess = { readonly __typename: 'MeSuccess', readonly persona: MyProfileQuery_me_MeSuccess_persona };

export type MyProfileQuery_me = MyProfileQuery_me_MeError | MyProfileQuery_me_MeSuccess;

export type MyProfileQuery_Query = { readonly __typename: 'Query', readonly me: MyProfileQuery_me };


export type MyProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type MyProfileQuery = MyProfileQuery_Query;

export type SaveConsumerProfileMutation_saveConsumerProfile_SaveConsumerProfileSuccess_persona_Admin_account_Account = { readonly __typename: 'Account', readonly phone?: string | undefined };

export type SaveConsumerProfileMutation_saveConsumerProfile_SaveConsumerProfileSuccess_persona_Admin = { readonly __typename: 'Admin', readonly firstName: string, readonly lastName: string, readonly displayName: string, readonly email: string, readonly account: SaveConsumerProfileMutation_saveConsumerProfile_SaveConsumerProfileSuccess_persona_Admin_account_Account };

export type SaveConsumerProfileMutation_saveConsumerProfile_SaveConsumerProfileSuccess_persona_Consumer = { readonly __typename: 'Consumer', readonly firstName: string, readonly lastName: string, readonly displayName: string, readonly email: string, readonly account: SaveConsumerProfileMutation_saveConsumerProfile_SaveConsumerProfileSuccess_persona_Admin_account_Account };

export type SaveConsumerProfileMutation_saveConsumerProfile_SaveConsumerProfileSuccess_persona_Moderator = { readonly __typename: 'Moderator', readonly firstName: string, readonly lastName: string, readonly displayName: string, readonly email: string, readonly account: SaveConsumerProfileMutation_saveConsumerProfile_SaveConsumerProfileSuccess_persona_Admin_account_Account };

export type SaveConsumerProfileMutation_saveConsumerProfile_SaveConsumerProfileSuccess_persona_Producer = { readonly __typename: 'Producer', readonly firstName: string, readonly lastName: string, readonly displayName: string, readonly email: string, readonly account: SaveConsumerProfileMutation_saveConsumerProfile_SaveConsumerProfileSuccess_persona_Admin_account_Account };

export type SaveConsumerProfileMutation_saveConsumerProfile_SaveConsumerProfileSuccess_persona = SaveConsumerProfileMutation_saveConsumerProfile_SaveConsumerProfileSuccess_persona_Admin | SaveConsumerProfileMutation_saveConsumerProfile_SaveConsumerProfileSuccess_persona_Consumer | SaveConsumerProfileMutation_saveConsumerProfile_SaveConsumerProfileSuccess_persona_Moderator | SaveConsumerProfileMutation_saveConsumerProfile_SaveConsumerProfileSuccess_persona_Producer;

export type SaveConsumerProfileMutation_saveConsumerProfile_SaveConsumerProfileError = { readonly __typename: 'SaveConsumerProfileError', readonly message: string };

export type SaveConsumerProfileMutation_saveConsumerProfile_SaveConsumerProfileSuccess = { readonly __typename: 'SaveConsumerProfileSuccess', readonly persona: SaveConsumerProfileMutation_saveConsumerProfile_SaveConsumerProfileSuccess_persona };

export type SaveConsumerProfileMutation_saveConsumerProfile = SaveConsumerProfileMutation_saveConsumerProfile_SaveConsumerProfileError | SaveConsumerProfileMutation_saveConsumerProfile_SaveConsumerProfileSuccess;

export type SaveConsumerProfileMutation_Mutation = { readonly __typename: 'Mutation', readonly saveConsumerProfile: SaveConsumerProfileMutation_saveConsumerProfile };


export type SaveConsumerProfileMutationVariables = Exact<{
  consumerProfile: SaveConsumerProfileInput;
}>;


export type SaveConsumerProfileMutation = SaveConsumerProfileMutation_Mutation;

export type SaveProducerProfileMutation_saveProducerProfile_SaveProducerProfileSuccess_persona_Admin_account_Account = { readonly __typename: 'Account', readonly phone?: string | undefined };

export type SaveProducerProfileMutation_saveProducerProfile_SaveProducerProfileSuccess_persona_Admin = { readonly __typename: 'Admin', readonly firstName: string, readonly lastName: string, readonly displayName: string, readonly email: string, readonly account: SaveProducerProfileMutation_saveProducerProfile_SaveProducerProfileSuccess_persona_Admin_account_Account };

export type SaveProducerProfileMutation_saveProducerProfile_SaveProducerProfileSuccess_persona_Consumer = { readonly __typename: 'Consumer', readonly firstName: string, readonly lastName: string, readonly displayName: string, readonly email: string, readonly account: SaveProducerProfileMutation_saveProducerProfile_SaveProducerProfileSuccess_persona_Admin_account_Account };

export type SaveProducerProfileMutation_saveProducerProfile_SaveProducerProfileSuccess_persona_Moderator = { readonly __typename: 'Moderator', readonly firstName: string, readonly lastName: string, readonly displayName: string, readonly email: string, readonly account: SaveProducerProfileMutation_saveProducerProfile_SaveProducerProfileSuccess_persona_Admin_account_Account };

export type SaveProducerProfileMutation_saveProducerProfile_SaveProducerProfileSuccess_persona_Producer = { readonly __typename: 'Producer', readonly firstName: string, readonly lastName: string, readonly displayName: string, readonly email: string, readonly account: SaveProducerProfileMutation_saveProducerProfile_SaveProducerProfileSuccess_persona_Admin_account_Account };

export type SaveProducerProfileMutation_saveProducerProfile_SaveProducerProfileSuccess_persona = SaveProducerProfileMutation_saveProducerProfile_SaveProducerProfileSuccess_persona_Admin | SaveProducerProfileMutation_saveProducerProfile_SaveProducerProfileSuccess_persona_Consumer | SaveProducerProfileMutation_saveProducerProfile_SaveProducerProfileSuccess_persona_Moderator | SaveProducerProfileMutation_saveProducerProfile_SaveProducerProfileSuccess_persona_Producer;

export type SaveProducerProfileMutation_saveProducerProfile_SaveProducerProfileError = { readonly __typename: 'SaveProducerProfileError', readonly message: string };

export type SaveProducerProfileMutation_saveProducerProfile_SaveProducerProfileSuccess = { readonly __typename: 'SaveProducerProfileSuccess', readonly persona: SaveProducerProfileMutation_saveProducerProfile_SaveProducerProfileSuccess_persona };

export type SaveProducerProfileMutation_saveProducerProfile = SaveProducerProfileMutation_saveProducerProfile_SaveProducerProfileError | SaveProducerProfileMutation_saveProducerProfile_SaveProducerProfileSuccess;

export type SaveProducerProfileMutation_Mutation = { readonly __typename: 'Mutation', readonly saveProducerProfile: SaveProducerProfileMutation_saveProducerProfile };


export type SaveProducerProfileMutationVariables = Exact<{
  producerProfile: SaveProducerProfileInput;
}>;


export type SaveProducerProfileMutation = SaveProducerProfileMutation_Mutation;

export type UpdateAvatarMutation_Mutation = { readonly __typename: 'Mutation', readonly updateAvatar: boolean };


export type UpdateAvatarMutationVariables = Exact<{
  avatar: Scalars['String']['input'];
  personaId: Scalars['ID']['input'];
}>;


export type UpdateAvatarMutation = UpdateAvatarMutation_Mutation;

export type RecipeDetailsQuery_recipes_RecipeConnection_node_Recipe_author_Admin = { readonly __typename: 'Admin', readonly displayName: string };

export type RecipeDetailsQuery_recipes_RecipeConnection_node_Recipe_author_Consumer = { readonly __typename: 'Consumer', readonly displayName: string };

export type RecipeDetailsQuery_recipes_RecipeConnection_node_Recipe_author_Moderator = { readonly __typename: 'Moderator', readonly displayName: string };

export type RecipeDetailsQuery_recipes_RecipeConnection_node_Recipe_author_Producer = { readonly __typename: 'Producer', readonly displayName: string };

export type RecipeDetailsQuery_recipes_RecipeConnection_node_Recipe_author = RecipeDetailsQuery_recipes_RecipeConnection_node_Recipe_author_Admin | RecipeDetailsQuery_recipes_RecipeConnection_node_Recipe_author_Consumer | RecipeDetailsQuery_recipes_RecipeConnection_node_Recipe_author_Moderator | RecipeDetailsQuery_recipes_RecipeConnection_node_Recipe_author_Producer;

export type RecipeDetailsQuery_recipes_RecipeConnection_node_Recipe_rating_Rating = { readonly __typename: 'Rating', readonly averageRating: number };

export type RecipeDetailsQuery_recipes_RecipeConnection_node_Recipe_category_Category = { readonly __typename: 'Category', readonly name: string };

export type RecipeDetailsQuery_recipes_RecipeConnection_node_Recipe = { readonly __typename: 'Recipe', readonly id: string, readonly title: string, readonly description: string, readonly imageUrl: string, readonly createdAt: Date, readonly additionalPhotos: ReadonlyArray<string>, readonly author: RecipeDetailsQuery_recipes_RecipeConnection_node_Recipe_author, readonly rating?: RecipeDetailsQuery_recipes_RecipeConnection_node_Recipe_rating_Rating | undefined, readonly category: RecipeDetailsQuery_recipes_RecipeConnection_node_Recipe_category_Category };

export type RecipeDetailsQuery_recipes_RecipeConnection = { readonly __typename: 'RecipeConnection', readonly node?: RecipeDetailsQuery_recipes_RecipeConnection_node_Recipe | undefined };

export type RecipeDetailsQuery_Query = { readonly __typename: 'Query', readonly recipes: RecipeDetailsQuery_recipes_RecipeConnection };


export type RecipeDetailsQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type RecipeDetailsQuery = RecipeDetailsQuery_Query;

export type RecipesListQuery_recipes_RecipeConnection_edges_RecipeEdge_node_Recipe_rating_Rating = { readonly __typename: 'Rating', readonly averageRating: number };

export type RecipesListQuery_recipes_RecipeConnection_edges_RecipeEdge_node_Recipe_author_Admin = { readonly __typename: 'Admin', readonly displayName: string, readonly id: string };

export type RecipesListQuery_recipes_RecipeConnection_edges_RecipeEdge_node_Recipe_author_Consumer = { readonly __typename: 'Consumer', readonly displayName: string, readonly id: string };

export type RecipesListQuery_recipes_RecipeConnection_edges_RecipeEdge_node_Recipe_author_Moderator = { readonly __typename: 'Moderator', readonly displayName: string, readonly id: string };

export type RecipesListQuery_recipes_RecipeConnection_edges_RecipeEdge_node_Recipe_author_Producer = { readonly __typename: 'Producer', readonly displayName: string, readonly id: string };

export type RecipesListQuery_recipes_RecipeConnection_edges_RecipeEdge_node_Recipe_author = RecipesListQuery_recipes_RecipeConnection_edges_RecipeEdge_node_Recipe_author_Admin | RecipesListQuery_recipes_RecipeConnection_edges_RecipeEdge_node_Recipe_author_Consumer | RecipesListQuery_recipes_RecipeConnection_edges_RecipeEdge_node_Recipe_author_Moderator | RecipesListQuery_recipes_RecipeConnection_edges_RecipeEdge_node_Recipe_author_Producer;

export type RecipesListQuery_recipes_RecipeConnection_edges_RecipeEdge_node_Recipe = { readonly __typename: 'Recipe', readonly id: string, readonly description: string, readonly title: string, readonly imageUrl: string, readonly createdAt: Date, readonly rating?: RecipesListQuery_recipes_RecipeConnection_edges_RecipeEdge_node_Recipe_rating_Rating | undefined, readonly author: RecipesListQuery_recipes_RecipeConnection_edges_RecipeEdge_node_Recipe_author };

export type RecipesListQuery_recipes_RecipeConnection_edges_RecipeEdge = { readonly __typename: 'RecipeEdge', readonly node?: RecipesListQuery_recipes_RecipeConnection_edges_RecipeEdge_node_Recipe | undefined };

export type RecipesListQuery_recipes_RecipeConnection = { readonly __typename: 'RecipeConnection', readonly edges?: ReadonlyArray<RecipesListQuery_recipes_RecipeConnection_edges_RecipeEdge | undefined> | undefined };

export type RecipesListQuery_Query = { readonly __typename: 'Query', readonly recipes: RecipesListQuery_recipes_RecipeConnection };


export type RecipesListQueryVariables = Exact<{
  first: Scalars['Int']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type RecipesListQuery = RecipesListQuery_Query;

export type SearchCategoriesQuery_categories_CategoryConnection_edges_CategoryEdge_node_Category = { readonly __typename: 'Category', readonly id: string, readonly name: string };

export type SearchCategoriesQuery_categories_CategoryConnection_edges_CategoryEdge = { readonly __typename: 'CategoryEdge', readonly node?: SearchCategoriesQuery_categories_CategoryConnection_edges_CategoryEdge_node_Category | undefined };

export type SearchCategoriesQuery_categories_CategoryConnection = { readonly __typename: 'CategoryConnection', readonly edges?: ReadonlyArray<SearchCategoriesQuery_categories_CategoryConnection_edges_CategoryEdge | undefined> | undefined };

export type SearchCategoriesQuery_Query = { readonly __typename: 'Query', readonly categories: SearchCategoriesQuery_categories_CategoryConnection };


export type SearchCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type SearchCategoriesQuery = SearchCategoriesQuery_Query;

export type SearchCitiesQuery_cities_CityConnection_edges_CityEdge_node_City = { readonly __typename: 'City', readonly id: string, readonly name: string };

export type SearchCitiesQuery_cities_CityConnection_edges_CityEdge = { readonly __typename: 'CityEdge', readonly node?: SearchCitiesQuery_cities_CityConnection_edges_CityEdge_node_City | undefined };

export type SearchCitiesQuery_cities_CityConnection = { readonly __typename: 'CityConnection', readonly edges?: ReadonlyArray<SearchCitiesQuery_cities_CityConnection_edges_CityEdge | undefined> | undefined };

export type SearchCitiesQuery_Query = { readonly __typename: 'Query', readonly cities: SearchCitiesQuery_cities_CityConnection };


export type SearchCitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type SearchCitiesQuery = SearchCitiesQuery_Query;

export type SearchDeliveryQuery_delivery_DeliveryConnection_edges_DeliveryEdge_node_Delivery = { readonly __typename: 'Delivery', readonly id: string, readonly name: string };

export type SearchDeliveryQuery_delivery_DeliveryConnection_edges_DeliveryEdge = { readonly __typename: 'DeliveryEdge', readonly node?: SearchDeliveryQuery_delivery_DeliveryConnection_edges_DeliveryEdge_node_Delivery | undefined };

export type SearchDeliveryQuery_delivery_DeliveryConnection = { readonly __typename: 'DeliveryConnection', readonly edges?: ReadonlyArray<SearchDeliveryQuery_delivery_DeliveryConnection_edges_DeliveryEdge | undefined> | undefined };

export type SearchDeliveryQuery_Query = { readonly __typename: 'Query', readonly delivery: SearchDeliveryQuery_delivery_DeliveryConnection };


export type SearchDeliveryQueryVariables = Exact<{ [key: string]: never; }>;


export type SearchDeliveryQuery = SearchDeliveryQuery_Query;

export type FilteredProductsQuery_products_ProductConnection_pageInfo_PageInfo = { readonly __typename: 'PageInfo', readonly hasNextPage: boolean, readonly hasPreviousPage: boolean, readonly startCursor?: string | undefined, readonly endCursor?: string | undefined };

export type FilteredProductsQuery_products_ProductConnection_edges_ProductEdge_node_Product_rating_Rating = { readonly __typename: 'Rating', readonly averageRating: number };

export type FilteredProductsQuery_products_ProductConnection_edges_ProductEdge_node_Product_category_Category = { readonly __typename: 'Category', readonly id: string, readonly name: string, readonly description: string };

export type FilteredProductsQuery_products_ProductConnection_edges_ProductEdge_node_Product_owner_Producer_delivery_Delivery = { readonly __typename: 'Delivery', readonly id: string, readonly name: string };

export type FilteredProductsQuery_products_ProductConnection_edges_ProductEdge_node_Product_owner_Producer_account_Account_location_Location_city_City = { readonly __typename: 'City', readonly id: string, readonly name: string };

export type FilteredProductsQuery_products_ProductConnection_edges_ProductEdge_node_Product_owner_Producer_account_Account_location_Location = { readonly __typename: 'Location', readonly city: FilteredProductsQuery_products_ProductConnection_edges_ProductEdge_node_Product_owner_Producer_account_Account_location_Location_city_City };

export type FilteredProductsQuery_products_ProductConnection_edges_ProductEdge_node_Product_owner_Producer_account_Account = { readonly __typename: 'Account', readonly location?: FilteredProductsQuery_products_ProductConnection_edges_ProductEdge_node_Product_owner_Producer_account_Account_location_Location | undefined };

export type FilteredProductsQuery_products_ProductConnection_edges_ProductEdge_node_Product_owner_Producer = { readonly __typename: 'Producer', readonly displayName: string, readonly delivery: ReadonlyArray<FilteredProductsQuery_products_ProductConnection_edges_ProductEdge_node_Product_owner_Producer_delivery_Delivery>, readonly account: FilteredProductsQuery_products_ProductConnection_edges_ProductEdge_node_Product_owner_Producer_account_Account };

export type FilteredProductsQuery_products_ProductConnection_edges_ProductEdge_node_Product = { readonly __typename: 'Product', readonly id: string, readonly title: string, readonly description: string, readonly imageUrl: string, readonly createdAt: Date, readonly rating?: FilteredProductsQuery_products_ProductConnection_edges_ProductEdge_node_Product_rating_Rating | undefined, readonly category: FilteredProductsQuery_products_ProductConnection_edges_ProductEdge_node_Product_category_Category, readonly owner: FilteredProductsQuery_products_ProductConnection_edges_ProductEdge_node_Product_owner_Producer };

export type FilteredProductsQuery_products_ProductConnection_edges_ProductEdge = { readonly __typename: 'ProductEdge', readonly cursor?: string | undefined, readonly node?: FilteredProductsQuery_products_ProductConnection_edges_ProductEdge_node_Product | undefined };

export type FilteredProductsQuery_products_ProductConnection = { readonly __typename: 'ProductConnection', readonly pageInfo: FilteredProductsQuery_products_ProductConnection_pageInfo_PageInfo, readonly edges?: ReadonlyArray<FilteredProductsQuery_products_ProductConnection_edges_ProductEdge | undefined> | undefined };

export type FilteredProductsQuery_Query = { readonly __typename: 'Query', readonly products: FilteredProductsQuery_products_ProductConnection };


export type FilteredProductsQueryVariables = Exact<{
  first: Scalars['Int']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProductFilterInput>;
}>;


export type FilteredProductsQuery = FilteredProductsQuery_Query;

export type SaveColorThemeMutation_Mutation = { readonly __typename: 'Mutation', readonly saveColorTheme: boolean };


export type SaveColorThemeMutationVariables = Exact<{
  themeType: Scalars['String']['input'];
}>;


export type SaveColorThemeMutation = SaveColorThemeMutation_Mutation;

export type MeQuery_me_MeSuccess_persona_Admin = { readonly __typename: 'Admin', readonly id: string, readonly email: string, readonly firstName: string, readonly lastName: string, readonly displayName: string, readonly avatarUrl?: string | undefined };

export type MeQuery_me_MeSuccess_persona_Consumer = { readonly __typename: 'Consumer', readonly id: string, readonly email: string, readonly firstName: string, readonly lastName: string, readonly displayName: string, readonly avatarUrl?: string | undefined };

export type MeQuery_me_MeSuccess_persona_Moderator = { readonly __typename: 'Moderator', readonly id: string, readonly email: string, readonly firstName: string, readonly lastName: string, readonly displayName: string, readonly avatarUrl?: string | undefined };

export type MeQuery_me_MeSuccess_persona_Producer = { readonly __typename: 'Producer', readonly id: string, readonly email: string, readonly firstName: string, readonly lastName: string, readonly displayName: string, readonly avatarUrl?: string | undefined };

export type MeQuery_me_MeSuccess_persona = MeQuery_me_MeSuccess_persona_Admin | MeQuery_me_MeSuccess_persona_Consumer | MeQuery_me_MeSuccess_persona_Moderator | MeQuery_me_MeSuccess_persona_Producer;

export type MeQuery_me_MeError = { readonly __typename: 'MeError', readonly message: string };

export type MeQuery_me_MeSuccess = { readonly __typename: 'MeSuccess', readonly persona: MeQuery_me_MeSuccess_persona };

export type MeQuery_me = MeQuery_me_MeError | MeQuery_me_MeSuccess;

export type MeQuery_Query = { readonly __typename: 'Query', readonly me: MeQuery_me };


export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = MeQuery_Query;

export type ForgotPasswordMutation_forgotPassword_ForgotPasswordError = { readonly __typename: 'ForgotPasswordError', readonly message: string };

export type ForgotPasswordMutation_forgotPassword_ForgotPasswordSuccess = { readonly __typename: 'ForgotPasswordSuccess', readonly message: string };

export type ForgotPasswordMutation_forgotPassword = ForgotPasswordMutation_forgotPassword_ForgotPasswordError | ForgotPasswordMutation_forgotPassword_ForgotPasswordSuccess;

export type ForgotPasswordMutation_Mutation = { readonly __typename: 'Mutation', readonly forgotPassword: ForgotPasswordMutation_forgotPassword };


export type ForgotPasswordMutationVariables = Exact<{
  userInfo: ForgotPasswordInput;
}>;


export type ForgotPasswordMutation = ForgotPasswordMutation_Mutation;

export type ConfirmMailMutation_Mutation = { readonly __typename: 'Mutation', readonly confirmMail: boolean };


export type ConfirmMailMutationVariables = Exact<{
  token?: InputMaybe<Scalars['String']['input']>;
}>;


export type ConfirmMailMutation = ConfirmMailMutation_Mutation;

export type ResetPasswordMutation_resetPassword_ResetPasswordError = { readonly __typename: 'ResetPasswordError', readonly message: string };

export type ResetPasswordMutation_resetPassword_ResetPasswordSuccess = { readonly __typename: 'ResetPasswordSuccess', readonly result: boolean };

export type ResetPasswordMutation_resetPassword = ResetPasswordMutation_resetPassword_ResetPasswordError | ResetPasswordMutation_resetPassword_ResetPasswordSuccess;

export type ResetPasswordMutation_Mutation = { readonly __typename: 'Mutation', readonly resetPassword: ResetPasswordMutation_resetPassword };


export type ResetPasswordMutationVariables = Exact<{
  userInfo: ResetPasswordInput;
}>;


export type ResetPasswordMutation = ResetPasswordMutation_Mutation;

export type SignUpMutation_signUp_SignUpSuccess_persona_Admin = { readonly __typename: 'Admin', readonly id: string, readonly email: string, readonly firstName: string, readonly lastName: string, readonly displayName: string, readonly avatarUrl?: string | undefined };

export type SignUpMutation_signUp_SignUpSuccess_persona_Consumer = { readonly __typename: 'Consumer', readonly id: string, readonly email: string, readonly firstName: string, readonly lastName: string, readonly displayName: string, readonly avatarUrl?: string | undefined };

export type SignUpMutation_signUp_SignUpSuccess_persona_Moderator = { readonly __typename: 'Moderator', readonly id: string, readonly email: string, readonly firstName: string, readonly lastName: string, readonly displayName: string, readonly avatarUrl?: string | undefined };

export type SignUpMutation_signUp_SignUpSuccess_persona_Producer = { readonly __typename: 'Producer', readonly id: string, readonly email: string, readonly firstName: string, readonly lastName: string, readonly displayName: string, readonly avatarUrl?: string | undefined };

export type SignUpMutation_signUp_SignUpSuccess_persona = SignUpMutation_signUp_SignUpSuccess_persona_Admin | SignUpMutation_signUp_SignUpSuccess_persona_Consumer | SignUpMutation_signUp_SignUpSuccess_persona_Moderator | SignUpMutation_signUp_SignUpSuccess_persona_Producer;

export type SignUpMutation_signUp_SignUpError = { readonly __typename: 'SignUpError', readonly message: string };

export type SignUpMutation_signUp_SignUpSuccess = { readonly __typename: 'SignUpSuccess', readonly persona: SignUpMutation_signUp_SignUpSuccess_persona };

export type SignUpMutation_signUp = SignUpMutation_signUp_SignUpError | SignUpMutation_signUp_SignUpSuccess;

export type SignUpMutation_Mutation = { readonly __typename: 'Mutation', readonly signUp: SignUpMutation_signUp };


export type SignUpMutationVariables = Exact<{
  userInfo: SignUpUserInfoInput;
}>;


export type SignUpMutation = SignUpMutation_Mutation;


export const OrderDetailsDocument = gql`
    query OrderDetails($orderId: String!) {
  orders(filter: {id: {eq: $orderId}}) {
    node {
      id
      createdAt
      totalPrice
      customer {
        displayName
        email
        avatarUrl
        account {
          phone
          location {
            city {
              id
              name
            }
          }
        }
      }
      orderStatus
      shippingAddress
      billingAddress
      orderStatusChange {
        status
        updatedAt
      }
      shipment {
        tracking
        carrier
        weight
        fee
        shippedAt
      }
      payment {
        transactionId
        transactionType
        total
        createdAt
      }
    }
  }
}
    `;

/**
 * __useOrderDetailsQuery__
 *
 * To run a query within a React component, call `useOrderDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderDetailsQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useOrderDetailsQuery(baseOptions: Apollo.QueryHookOptions<OrderDetailsQuery, OrderDetailsQueryVariables> & ({ variables: OrderDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderDetailsQuery, OrderDetailsQueryVariables>(OrderDetailsDocument, options);
      }
export function useOrderDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderDetailsQuery, OrderDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderDetailsQuery, OrderDetailsQueryVariables>(OrderDetailsDocument, options);
        }
export function useOrderDetailsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OrderDetailsQuery, OrderDetailsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrderDetailsQuery, OrderDetailsQueryVariables>(OrderDetailsDocument, options);
        }
export type OrderDetailsQueryHookResult = ReturnType<typeof useOrderDetailsQuery>;
export type OrderDetailsLazyQueryHookResult = ReturnType<typeof useOrderDetailsLazyQuery>;
export type OrderDetailsSuspenseQueryHookResult = ReturnType<typeof useOrderDetailsSuspenseQuery>;
export type OrderDetailsQueryResult = Apollo.QueryResult<OrderDetailsQuery, OrderDetailsQueryVariables>;
export const OrderInvoiceDocument = gql`
    query OrderInvoice($orderId: String!) {
  orders(filter: {id: {eq: $orderId}}) {
    node {
      id
      shippingAddress
      billingAddress
      customer {
        displayName
        email
        avatarUrl
        account {
          phone
          location {
            city {
              id
              name
            }
          }
        }
      }
      producer {
        id
        displayName
        email
        avatarUrl
        account {
          phone
          location {
            city {
              id
              name
            }
          }
        }
      }
      products {
        quantity
        product {
          id
          title
          price
          imageUrl
        }
      }
      invoice {
        createdAt
        subtotal
        tax
        discount
        total
      }
    }
  }
}
    `;

/**
 * __useOrderInvoiceQuery__
 *
 * To run a query within a React component, call `useOrderInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderInvoiceQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useOrderInvoiceQuery(baseOptions: Apollo.QueryHookOptions<OrderInvoiceQuery, OrderInvoiceQueryVariables> & ({ variables: OrderInvoiceQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderInvoiceQuery, OrderInvoiceQueryVariables>(OrderInvoiceDocument, options);
      }
export function useOrderInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderInvoiceQuery, OrderInvoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderInvoiceQuery, OrderInvoiceQueryVariables>(OrderInvoiceDocument, options);
        }
export function useOrderInvoiceSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OrderInvoiceQuery, OrderInvoiceQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrderInvoiceQuery, OrderInvoiceQueryVariables>(OrderInvoiceDocument, options);
        }
export type OrderInvoiceQueryHookResult = ReturnType<typeof useOrderInvoiceQuery>;
export type OrderInvoiceLazyQueryHookResult = ReturnType<typeof useOrderInvoiceLazyQuery>;
export type OrderInvoiceSuspenseQueryHookResult = ReturnType<typeof useOrderInvoiceSuspenseQuery>;
export type OrderInvoiceQueryResult = Apollo.QueryResult<OrderInvoiceQuery, OrderInvoiceQueryVariables>;
export const OrderProductsDocument = gql`
    query OrderProducts($orderId: String!) {
  orders(filter: {id: {eq: $orderId}}) {
    node {
      id
      products {
        quantity
        product {
          id
          title
          price
          imageUrl
        }
      }
    }
  }
}
    `;

/**
 * __useOrderProductsQuery__
 *
 * To run a query within a React component, call `useOrderProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderProductsQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useOrderProductsQuery(baseOptions: Apollo.QueryHookOptions<OrderProductsQuery, OrderProductsQueryVariables> & ({ variables: OrderProductsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderProductsQuery, OrderProductsQueryVariables>(OrderProductsDocument, options);
      }
export function useOrderProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderProductsQuery, OrderProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderProductsQuery, OrderProductsQueryVariables>(OrderProductsDocument, options);
        }
export function useOrderProductsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OrderProductsQuery, OrderProductsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrderProductsQuery, OrderProductsQueryVariables>(OrderProductsDocument, options);
        }
export type OrderProductsQueryHookResult = ReturnType<typeof useOrderProductsQuery>;
export type OrderProductsLazyQueryHookResult = ReturnType<typeof useOrderProductsLazyQuery>;
export type OrderProductsSuspenseQueryHookResult = ReturnType<typeof useOrderProductsSuspenseQuery>;
export type OrderProductsQueryResult = Apollo.QueryResult<OrderProductsQuery, OrderProductsQueryVariables>;
export const OrdersDocument = gql`
    query Orders($orderStatus: String!) {
  orders(filter: {orderStatus: {eq: $orderStatus}}) {
    edges {
      node {
        id
        createdAt
        totalPrice
        customer {
          displayName
          email
          avatarUrl
          account {
            phone
            location {
              city {
                id
                name
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useOrdersQuery__
 *
 * To run a query within a React component, call `useOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersQuery({
 *   variables: {
 *      orderStatus: // value for 'orderStatus'
 *   },
 * });
 */
export function useOrdersQuery(baseOptions: Apollo.QueryHookOptions<OrdersQuery, OrdersQueryVariables> & ({ variables: OrdersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
      }
export function useOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
        }
export function useOrdersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
        }
export type OrdersQueryHookResult = ReturnType<typeof useOrdersQuery>;
export type OrdersLazyQueryHookResult = ReturnType<typeof useOrdersLazyQuery>;
export type OrdersSuspenseQueryHookResult = ReturnType<typeof useOrdersSuspenseQuery>;
export type OrdersQueryResult = Apollo.QueryResult<OrdersQuery, OrdersQueryVariables>;
export const CategoriesDocument = gql`
    query Categories($filter: CategoryFilterInput, $sort: SortInput, $first: Int, $after: String) {
  categories(filter: $filter, sort: $sort, first: $first, after: $after) {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useCategoriesQuery__
 *
 * To run a query within a React component, call `useCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, options);
      }
export function useCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, options);
        }
export function useCategoriesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, options);
        }
export type CategoriesQueryHookResult = ReturnType<typeof useCategoriesQuery>;
export type CategoriesLazyQueryHookResult = ReturnType<typeof useCategoriesLazyQuery>;
export type CategoriesSuspenseQueryHookResult = ReturnType<typeof useCategoriesSuspenseQuery>;
export type CategoriesQueryResult = Apollo.QueryResult<CategoriesQuery, CategoriesQueryVariables>;
export const DeleteProductDocument = gql`
    mutation DeleteProduct($productId: ID!) {
  deleteProduct(productId: $productId) {
    __typename
    ... on DeleteProductSuccess {
      message
    }
    ... on DeleteProductError {
      message
    }
  }
}
    `;
export type DeleteProductMutationFn = Apollo.MutationFunction<DeleteProductMutation, DeleteProductMutationVariables>;

/**
 * __useDeleteProductMutation__
 *
 * To run a mutation, you first call `useDeleteProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductMutation, { data, loading, error }] = useDeleteProductMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useDeleteProductMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProductMutation, DeleteProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProductMutation, DeleteProductMutationVariables>(DeleteProductDocument, options);
      }
export type DeleteProductMutationHookResult = ReturnType<typeof useDeleteProductMutation>;
export type DeleteProductMutationResult = Apollo.MutationResult<DeleteProductMutation>;
export type DeleteProductMutationOptions = Apollo.BaseMutationOptions<DeleteProductMutation, DeleteProductMutationVariables>;
export const MyProductsDocument = gql`
    query MyProducts {
  me {
    ... on MeSuccess {
      persona {
        ... on Producer {
          products {
            edges {
              node {
                id
                category {
                  name
                }
                price
                title
                imageUrl
                rating {
                  averageRating
                }
                createdAt
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useMyProductsQuery__
 *
 * To run a query within a React component, call `useMyProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyProductsQuery(baseOptions?: Apollo.QueryHookOptions<MyProductsQuery, MyProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyProductsQuery, MyProductsQueryVariables>(MyProductsDocument, options);
      }
export function useMyProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyProductsQuery, MyProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyProductsQuery, MyProductsQueryVariables>(MyProductsDocument, options);
        }
export function useMyProductsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MyProductsQuery, MyProductsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MyProductsQuery, MyProductsQueryVariables>(MyProductsDocument, options);
        }
export type MyProductsQueryHookResult = ReturnType<typeof useMyProductsQuery>;
export type MyProductsLazyQueryHookResult = ReturnType<typeof useMyProductsLazyQuery>;
export type MyProductsSuspenseQueryHookResult = ReturnType<typeof useMyProductsSuspenseQuery>;
export type MyProductsQueryResult = Apollo.QueryResult<MyProductsQuery, MyProductsQueryVariables>;
export const ProductDocument = gql`
    query Product($id: String!) {
  products(filter: {id: {eq: $id}}) {
    node {
      title
      description
      category {
        id
        name
      }
      imageUrl
      price
      depth
      extraShippingFee
      height
      weight
      width
      tax
      priceNoTax
      priceWithTax
    }
  }
}
    `;

/**
 * __useProductQuery__
 *
 * To run a query within a React component, call `useProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductQuery(baseOptions: Apollo.QueryHookOptions<ProductQuery, ProductQueryVariables> & ({ variables: ProductQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
      }
export function useProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductQuery, ProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
        }
export function useProductSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ProductQuery, ProductQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
        }
export type ProductQueryHookResult = ReturnType<typeof useProductQuery>;
export type ProductLazyQueryHookResult = ReturnType<typeof useProductLazyQuery>;
export type ProductSuspenseQueryHookResult = ReturnType<typeof useProductSuspenseQuery>;
export type ProductQueryResult = Apollo.QueryResult<ProductQuery, ProductQueryVariables>;
export const SaveProductDocument = gql`
    mutation SaveProduct($productInput: SaveProductInput!) {
  saveProduct(productInput: $productInput) {
    ... on SaveProductSuccess {
      product {
        id
        title
        description
        price
        imageUrl
        category {
          id
          name
        }
        depth
        extraShippingFee
        height
        weight
        width
        tax
        priceNoTax
        priceWithTax
      }
    }
    ... on SaveProductError {
      message
    }
  }
}
    `;
export type SaveProductMutationFn = Apollo.MutationFunction<SaveProductMutation, SaveProductMutationVariables>;

/**
 * __useSaveProductMutation__
 *
 * To run a mutation, you first call `useSaveProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveProductMutation, { data, loading, error }] = useSaveProductMutation({
 *   variables: {
 *      productInput: // value for 'productInput'
 *   },
 * });
 */
export function useSaveProductMutation(baseOptions?: Apollo.MutationHookOptions<SaveProductMutation, SaveProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveProductMutation, SaveProductMutationVariables>(SaveProductDocument, options);
      }
export type SaveProductMutationHookResult = ReturnType<typeof useSaveProductMutation>;
export type SaveProductMutationResult = Apollo.MutationResult<SaveProductMutation>;
export type SaveProductMutationOptions = Apollo.BaseMutationOptions<SaveProductMutation, SaveProductMutationVariables>;
export const UpdateProductDocument = gql`
    mutation UpdateProduct($productInput: SaveProductInput!) {
  saveProduct(productInput: $productInput) {
    ... on SaveProductSuccess {
      product {
        id
        title
        description
        price
        imageUrl
        category {
          id
          name
        }
        depth
        extraShippingFee
        height
        weight
        width
        tax
        priceNoTax
        priceWithTax
      }
    }
    ... on SaveProductError {
      message
    }
  }
}
    `;
export type UpdateProductMutationFn = Apollo.MutationFunction<UpdateProductMutation, UpdateProductMutationVariables>;

/**
 * __useUpdateProductMutation__
 *
 * To run a mutation, you first call `useUpdateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductMutation, { data, loading, error }] = useUpdateProductMutation({
 *   variables: {
 *      productInput: // value for 'productInput'
 *   },
 * });
 */
export function useUpdateProductMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductMutation, UpdateProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductMutation, UpdateProductMutationVariables>(UpdateProductDocument, options);
      }
export type UpdateProductMutationHookResult = ReturnType<typeof useUpdateProductMutation>;
export type UpdateProductMutationResult = Apollo.MutationResult<UpdateProductMutation>;
export type UpdateProductMutationOptions = Apollo.BaseMutationOptions<UpdateProductMutation, UpdateProductMutationVariables>;
export const DeleteRecipeDocument = gql`
    mutation DeleteRecipe($recipeId: ID!) {
  deleteRecipe(recipeId: $recipeId) {
    __typename
    ... on DeleteRecipeSuccess {
      message
    }
    ... on DeleteRecipeError {
      message
    }
  }
}
    `;
export type DeleteRecipeMutationFn = Apollo.MutationFunction<DeleteRecipeMutation, DeleteRecipeMutationVariables>;

/**
 * __useDeleteRecipeMutation__
 *
 * To run a mutation, you first call `useDeleteRecipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRecipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRecipeMutation, { data, loading, error }] = useDeleteRecipeMutation({
 *   variables: {
 *      recipeId: // value for 'recipeId'
 *   },
 * });
 */
export function useDeleteRecipeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRecipeMutation, DeleteRecipeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRecipeMutation, DeleteRecipeMutationVariables>(DeleteRecipeDocument, options);
      }
export type DeleteRecipeMutationHookResult = ReturnType<typeof useDeleteRecipeMutation>;
export type DeleteRecipeMutationResult = Apollo.MutationResult<DeleteRecipeMutation>;
export type DeleteRecipeMutationOptions = Apollo.BaseMutationOptions<DeleteRecipeMutation, DeleteRecipeMutationVariables>;
export const MyRecipesDocument = gql`
    query MyRecipes {
  me {
    ... on MeSuccess {
      persona {
        ... on Producer {
          recipes {
            edges {
              node {
                id
                title
                imageUrl
                rating {
                  averageRating
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useMyRecipesQuery__
 *
 * To run a query within a React component, call `useMyRecipesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyRecipesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyRecipesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyRecipesQuery(baseOptions?: Apollo.QueryHookOptions<MyRecipesQuery, MyRecipesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyRecipesQuery, MyRecipesQueryVariables>(MyRecipesDocument, options);
      }
export function useMyRecipesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyRecipesQuery, MyRecipesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyRecipesQuery, MyRecipesQueryVariables>(MyRecipesDocument, options);
        }
export function useMyRecipesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MyRecipesQuery, MyRecipesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MyRecipesQuery, MyRecipesQueryVariables>(MyRecipesDocument, options);
        }
export type MyRecipesQueryHookResult = ReturnType<typeof useMyRecipesQuery>;
export type MyRecipesLazyQueryHookResult = ReturnType<typeof useMyRecipesLazyQuery>;
export type MyRecipesSuspenseQueryHookResult = ReturnType<typeof useMyRecipesSuspenseQuery>;
export type MyRecipesQueryResult = Apollo.QueryResult<MyRecipesQuery, MyRecipesQueryVariables>;
export const RecipeDocument = gql`
    query Recipe($id: String!) {
  recipes(filter: {id: {eq: $id}}) {
    node {
      author {
        firstName
        lastName
      }
      additionalPhotos
      title
      description
      category {
        id
        name
      }
      createdAt
      imageUrl
    }
  }
}
    `;

/**
 * __useRecipeQuery__
 *
 * To run a query within a React component, call `useRecipeQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecipeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecipeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRecipeQuery(baseOptions: Apollo.QueryHookOptions<RecipeQuery, RecipeQueryVariables> & ({ variables: RecipeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecipeQuery, RecipeQueryVariables>(RecipeDocument, options);
      }
export function useRecipeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecipeQuery, RecipeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecipeQuery, RecipeQueryVariables>(RecipeDocument, options);
        }
export function useRecipeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RecipeQuery, RecipeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RecipeQuery, RecipeQueryVariables>(RecipeDocument, options);
        }
export type RecipeQueryHookResult = ReturnType<typeof useRecipeQuery>;
export type RecipeLazyQueryHookResult = ReturnType<typeof useRecipeLazyQuery>;
export type RecipeSuspenseQueryHookResult = ReturnType<typeof useRecipeSuspenseQuery>;
export type RecipeQueryResult = Apollo.QueryResult<RecipeQuery, RecipeQueryVariables>;
export const SaveRecipeDocument = gql`
    mutation SaveRecipe($recipeInput: SaveRecipeInput!) {
  saveRecipe(recipeInput: $recipeInput) {
    ... on SaveRecipeSuccess {
      recipe {
        id
        imageUrl
        title
        description
        category {
          description
          id
          name
        }
      }
    }
    ... on SaveRecipeError {
      message
    }
  }
}
    `;
export type SaveRecipeMutationFn = Apollo.MutationFunction<SaveRecipeMutation, SaveRecipeMutationVariables>;

/**
 * __useSaveRecipeMutation__
 *
 * To run a mutation, you first call `useSaveRecipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveRecipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveRecipeMutation, { data, loading, error }] = useSaveRecipeMutation({
 *   variables: {
 *      recipeInput: // value for 'recipeInput'
 *   },
 * });
 */
export function useSaveRecipeMutation(baseOptions?: Apollo.MutationHookOptions<SaveRecipeMutation, SaveRecipeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveRecipeMutation, SaveRecipeMutationVariables>(SaveRecipeDocument, options);
      }
export type SaveRecipeMutationHookResult = ReturnType<typeof useSaveRecipeMutation>;
export type SaveRecipeMutationResult = Apollo.MutationResult<SaveRecipeMutation>;
export type SaveRecipeMutationOptions = Apollo.BaseMutationOptions<SaveRecipeMutation, SaveRecipeMutationVariables>;
export const UpdateRecipeDocument = gql`
    mutation UpdateRecipe($recipeInput: SaveRecipeInput!) {
  saveRecipe(recipeInput: $recipeInput) {
    ... on SaveRecipeSuccess {
      recipe {
        id
        imageUrl
        title
        description
        category {
          description
          id
          name
        }
      }
    }
    ... on SaveRecipeError {
      message
    }
  }
}
    `;
export type UpdateRecipeMutationFn = Apollo.MutationFunction<UpdateRecipeMutation, UpdateRecipeMutationVariables>;

/**
 * __useUpdateRecipeMutation__
 *
 * To run a mutation, you first call `useUpdateRecipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecipeMutation, { data, loading, error }] = useUpdateRecipeMutation({
 *   variables: {
 *      recipeInput: // value for 'recipeInput'
 *   },
 * });
 */
export function useUpdateRecipeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRecipeMutation, UpdateRecipeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRecipeMutation, UpdateRecipeMutationVariables>(UpdateRecipeDocument, options);
      }
export type UpdateRecipeMutationHookResult = ReturnType<typeof useUpdateRecipeMutation>;
export type UpdateRecipeMutationResult = Apollo.MutationResult<UpdateRecipeMutation>;
export type UpdateRecipeMutationOptions = Apollo.BaseMutationOptions<UpdateRecipeMutation, UpdateRecipeMutationVariables>;
export const MySalesDocument = gql`
    query MySales {
  me {
    ... on MeSuccess {
      persona {
        ... on Producer {
          sales {
            edges {
              node {
                id
                customer {
                  id
                  displayName
                }
                createdAt
                totalPrice
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useMySalesQuery__
 *
 * To run a query within a React component, call `useMySalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMySalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMySalesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMySalesQuery(baseOptions?: Apollo.QueryHookOptions<MySalesQuery, MySalesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MySalesQuery, MySalesQueryVariables>(MySalesDocument, options);
      }
export function useMySalesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MySalesQuery, MySalesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MySalesQuery, MySalesQueryVariables>(MySalesDocument, options);
        }
export function useMySalesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MySalesQuery, MySalesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MySalesQuery, MySalesQueryVariables>(MySalesDocument, options);
        }
export type MySalesQueryHookResult = ReturnType<typeof useMySalesQuery>;
export type MySalesLazyQueryHookResult = ReturnType<typeof useMySalesLazyQuery>;
export type MySalesSuspenseQueryHookResult = ReturnType<typeof useMySalesSuspenseQuery>;
export type MySalesQueryResult = Apollo.QueryResult<MySalesQuery, MySalesQueryVariables>;
export const ProductDetailsDocument = gql`
    query ProductDetails($id: String!) {
  products(filter: {id: {eq: $id}}) {
    node {
      title
      id
      description
      imageUrl
      owner {
        firstName
        delivery {
          id
          name
        }
        account {
          location {
            city {
              id
              name
            }
          }
        }
      }
      rating {
        averageRating
      }
      category {
        id
        name
      }
      additionalPhotos
    }
  }
}
    `;

/**
 * __useProductDetailsQuery__
 *
 * To run a query within a React component, call `useProductDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductDetailsQuery(baseOptions: Apollo.QueryHookOptions<ProductDetailsQuery, ProductDetailsQueryVariables> & ({ variables: ProductDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductDetailsQuery, ProductDetailsQueryVariables>(ProductDetailsDocument, options);
      }
export function useProductDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductDetailsQuery, ProductDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductDetailsQuery, ProductDetailsQueryVariables>(ProductDetailsDocument, options);
        }
export function useProductDetailsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ProductDetailsQuery, ProductDetailsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProductDetailsQuery, ProductDetailsQueryVariables>(ProductDetailsDocument, options);
        }
export type ProductDetailsQueryHookResult = ReturnType<typeof useProductDetailsQuery>;
export type ProductDetailsLazyQueryHookResult = ReturnType<typeof useProductDetailsLazyQuery>;
export type ProductDetailsSuspenseQueryHookResult = ReturnType<typeof useProductDetailsSuspenseQuery>;
export type ProductDetailsQueryResult = Apollo.QueryResult<ProductDetailsQuery, ProductDetailsQueryVariables>;
export const ProductsByTypeDocument = gql`
    query ProductsByType($productType: String!) {
  products(first: 4, filter: {type: {eq: $productType}}) {
    edges {
      node {
        id
        title
        imageUrl
        category {
          name
        }
        rating {
          averageRating
        }
        owner {
          displayName
        }
      }
    }
  }
}
    `;

/**
 * __useProductsByTypeQuery__
 *
 * To run a query within a React component, call `useProductsByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsByTypeQuery({
 *   variables: {
 *      productType: // value for 'productType'
 *   },
 * });
 */
export function useProductsByTypeQuery(baseOptions: Apollo.QueryHookOptions<ProductsByTypeQuery, ProductsByTypeQueryVariables> & ({ variables: ProductsByTypeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductsByTypeQuery, ProductsByTypeQueryVariables>(ProductsByTypeDocument, options);
      }
export function useProductsByTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductsByTypeQuery, ProductsByTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductsByTypeQuery, ProductsByTypeQueryVariables>(ProductsByTypeDocument, options);
        }
export function useProductsByTypeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ProductsByTypeQuery, ProductsByTypeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProductsByTypeQuery, ProductsByTypeQueryVariables>(ProductsByTypeDocument, options);
        }
export type ProductsByTypeQueryHookResult = ReturnType<typeof useProductsByTypeQuery>;
export type ProductsByTypeLazyQueryHookResult = ReturnType<typeof useProductsByTypeLazyQuery>;
export type ProductsByTypeSuspenseQueryHookResult = ReturnType<typeof useProductsByTypeSuspenseQuery>;
export type ProductsByTypeQueryResult = Apollo.QueryResult<ProductsByTypeQuery, ProductsByTypeQueryVariables>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($passwordInput: ChangePasswordInput!) {
  changePassword(passwordInput: $passwordInput) {
    ... on ChangePasswordSuccess {
      persona {
        ... on Persona {
          id
          email
          firstName
          lastName
          displayName
        }
      }
    }
    ... on ChangePasswordError {
      message
    }
  }
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      passwordInput: // value for 'passwordInput'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const MyProfileDocument = gql`
    query myProfile {
  me {
    ... on MeSuccess {
      persona {
        ... on Persona {
          id
          displayName
          email
          avatarUrl
          email
          firstName
          lastName
          account {
            phone
          }
        }
      }
    }
    ... on Error {
      message
    }
  }
}
    `;

/**
 * __useMyProfileQuery__
 *
 * To run a query within a React component, call `useMyProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyProfileQuery(baseOptions?: Apollo.QueryHookOptions<MyProfileQuery, MyProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyProfileQuery, MyProfileQueryVariables>(MyProfileDocument, options);
      }
export function useMyProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyProfileQuery, MyProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyProfileQuery, MyProfileQueryVariables>(MyProfileDocument, options);
        }
export function useMyProfileSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MyProfileQuery, MyProfileQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MyProfileQuery, MyProfileQueryVariables>(MyProfileDocument, options);
        }
export type MyProfileQueryHookResult = ReturnType<typeof useMyProfileQuery>;
export type MyProfileLazyQueryHookResult = ReturnType<typeof useMyProfileLazyQuery>;
export type MyProfileSuspenseQueryHookResult = ReturnType<typeof useMyProfileSuspenseQuery>;
export type MyProfileQueryResult = Apollo.QueryResult<MyProfileQuery, MyProfileQueryVariables>;
export const SaveConsumerProfileDocument = gql`
    mutation SaveConsumerProfile($consumerProfile: SaveConsumerProfileInput!) {
  saveConsumerProfile(consumerProfile: $consumerProfile) {
    ... on SaveConsumerProfileSuccess {
      __typename
      persona {
        ... on Persona {
          firstName
          lastName
          displayName
          email
          account {
            phone
          }
        }
      }
    }
    ... on SaveConsumerProfileError {
      __typename
      message
    }
  }
}
    `;
export type SaveConsumerProfileMutationFn = Apollo.MutationFunction<SaveConsumerProfileMutation, SaveConsumerProfileMutationVariables>;

/**
 * __useSaveConsumerProfileMutation__
 *
 * To run a mutation, you first call `useSaveConsumerProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveConsumerProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveConsumerProfileMutation, { data, loading, error }] = useSaveConsumerProfileMutation({
 *   variables: {
 *      consumerProfile: // value for 'consumerProfile'
 *   },
 * });
 */
export function useSaveConsumerProfileMutation(baseOptions?: Apollo.MutationHookOptions<SaveConsumerProfileMutation, SaveConsumerProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveConsumerProfileMutation, SaveConsumerProfileMutationVariables>(SaveConsumerProfileDocument, options);
      }
export type SaveConsumerProfileMutationHookResult = ReturnType<typeof useSaveConsumerProfileMutation>;
export type SaveConsumerProfileMutationResult = Apollo.MutationResult<SaveConsumerProfileMutation>;
export type SaveConsumerProfileMutationOptions = Apollo.BaseMutationOptions<SaveConsumerProfileMutation, SaveConsumerProfileMutationVariables>;
export const SaveProducerProfileDocument = gql`
    mutation SaveProducerProfile($producerProfile: SaveProducerProfileInput!) {
  saveProducerProfile(producerProfile: $producerProfile) {
    ... on SaveProducerProfileSuccess {
      __typename
      persona {
        ... on Persona {
          firstName
          lastName
          displayName
          email
          account {
            phone
          }
        }
      }
    }
    ... on SaveProducerProfileError {
      __typename
      message
    }
  }
}
    `;
export type SaveProducerProfileMutationFn = Apollo.MutationFunction<SaveProducerProfileMutation, SaveProducerProfileMutationVariables>;

/**
 * __useSaveProducerProfileMutation__
 *
 * To run a mutation, you first call `useSaveProducerProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveProducerProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveProducerProfileMutation, { data, loading, error }] = useSaveProducerProfileMutation({
 *   variables: {
 *      producerProfile: // value for 'producerProfile'
 *   },
 * });
 */
export function useSaveProducerProfileMutation(baseOptions?: Apollo.MutationHookOptions<SaveProducerProfileMutation, SaveProducerProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveProducerProfileMutation, SaveProducerProfileMutationVariables>(SaveProducerProfileDocument, options);
      }
export type SaveProducerProfileMutationHookResult = ReturnType<typeof useSaveProducerProfileMutation>;
export type SaveProducerProfileMutationResult = Apollo.MutationResult<SaveProducerProfileMutation>;
export type SaveProducerProfileMutationOptions = Apollo.BaseMutationOptions<SaveProducerProfileMutation, SaveProducerProfileMutationVariables>;
export const UpdateAvatarDocument = gql`
    mutation UpdateAvatar($avatar: String!, $personaId: ID!) {
  updateAvatar(avatar: $avatar, personaId: $personaId)
}
    `;
export type UpdateAvatarMutationFn = Apollo.MutationFunction<UpdateAvatarMutation, UpdateAvatarMutationVariables>;

/**
 * __useUpdateAvatarMutation__
 *
 * To run a mutation, you first call `useUpdateAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAvatarMutation, { data, loading, error }] = useUpdateAvatarMutation({
 *   variables: {
 *      avatar: // value for 'avatar'
 *      personaId: // value for 'personaId'
 *   },
 * });
 */
export function useUpdateAvatarMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAvatarMutation, UpdateAvatarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAvatarMutation, UpdateAvatarMutationVariables>(UpdateAvatarDocument, options);
      }
export type UpdateAvatarMutationHookResult = ReturnType<typeof useUpdateAvatarMutation>;
export type UpdateAvatarMutationResult = Apollo.MutationResult<UpdateAvatarMutation>;
export type UpdateAvatarMutationOptions = Apollo.BaseMutationOptions<UpdateAvatarMutation, UpdateAvatarMutationVariables>;
export const RecipeDetailsDocument = gql`
    query RecipeDetails($id: String!) {
  recipes(filter: {id: {eq: $id}}) {
    node {
      id
      title
      description
      imageUrl
      author {
        displayName
      }
      rating {
        averageRating
      }
      createdAt
      additionalPhotos
      category {
        name
      }
    }
  }
}
    `;

/**
 * __useRecipeDetailsQuery__
 *
 * To run a query within a React component, call `useRecipeDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecipeDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecipeDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRecipeDetailsQuery(baseOptions: Apollo.QueryHookOptions<RecipeDetailsQuery, RecipeDetailsQueryVariables> & ({ variables: RecipeDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecipeDetailsQuery, RecipeDetailsQueryVariables>(RecipeDetailsDocument, options);
      }
export function useRecipeDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecipeDetailsQuery, RecipeDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecipeDetailsQuery, RecipeDetailsQueryVariables>(RecipeDetailsDocument, options);
        }
export function useRecipeDetailsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RecipeDetailsQuery, RecipeDetailsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RecipeDetailsQuery, RecipeDetailsQueryVariables>(RecipeDetailsDocument, options);
        }
export type RecipeDetailsQueryHookResult = ReturnType<typeof useRecipeDetailsQuery>;
export type RecipeDetailsLazyQueryHookResult = ReturnType<typeof useRecipeDetailsLazyQuery>;
export type RecipeDetailsSuspenseQueryHookResult = ReturnType<typeof useRecipeDetailsSuspenseQuery>;
export type RecipeDetailsQueryResult = Apollo.QueryResult<RecipeDetailsQuery, RecipeDetailsQueryVariables>;
export const RecipesListDocument = gql`
    query RecipesList($first: Int!, $after: String) {
  recipes(first: $first, after: $after) {
    edges {
      node {
        id
        description
        title
        rating {
          averageRating
        }
        imageUrl
        createdAt
        author {
          displayName
          id
        }
      }
    }
  }
}
    `;

/**
 * __useRecipesListQuery__
 *
 * To run a query within a React component, call `useRecipesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecipesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecipesListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useRecipesListQuery(baseOptions: Apollo.QueryHookOptions<RecipesListQuery, RecipesListQueryVariables> & ({ variables: RecipesListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecipesListQuery, RecipesListQueryVariables>(RecipesListDocument, options);
      }
export function useRecipesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecipesListQuery, RecipesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecipesListQuery, RecipesListQueryVariables>(RecipesListDocument, options);
        }
export function useRecipesListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RecipesListQuery, RecipesListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RecipesListQuery, RecipesListQueryVariables>(RecipesListDocument, options);
        }
export type RecipesListQueryHookResult = ReturnType<typeof useRecipesListQuery>;
export type RecipesListLazyQueryHookResult = ReturnType<typeof useRecipesListLazyQuery>;
export type RecipesListSuspenseQueryHookResult = ReturnType<typeof useRecipesListSuspenseQuery>;
export type RecipesListQueryResult = Apollo.QueryResult<RecipesListQuery, RecipesListQueryVariables>;
export const SearchCategoriesDocument = gql`
    query SearchCategories {
  categories {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useSearchCategoriesQuery__
 *
 * To run a query within a React component, call `useSearchCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSearchCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<SearchCategoriesQuery, SearchCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchCategoriesQuery, SearchCategoriesQueryVariables>(SearchCategoriesDocument, options);
      }
export function useSearchCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchCategoriesQuery, SearchCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchCategoriesQuery, SearchCategoriesQueryVariables>(SearchCategoriesDocument, options);
        }
export function useSearchCategoriesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SearchCategoriesQuery, SearchCategoriesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchCategoriesQuery, SearchCategoriesQueryVariables>(SearchCategoriesDocument, options);
        }
export type SearchCategoriesQueryHookResult = ReturnType<typeof useSearchCategoriesQuery>;
export type SearchCategoriesLazyQueryHookResult = ReturnType<typeof useSearchCategoriesLazyQuery>;
export type SearchCategoriesSuspenseQueryHookResult = ReturnType<typeof useSearchCategoriesSuspenseQuery>;
export type SearchCategoriesQueryResult = Apollo.QueryResult<SearchCategoriesQuery, SearchCategoriesQueryVariables>;
export const SearchCitiesDocument = gql`
    query SearchCities {
  cities {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useSearchCitiesQuery__
 *
 * To run a query within a React component, call `useSearchCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSearchCitiesQuery(baseOptions?: Apollo.QueryHookOptions<SearchCitiesQuery, SearchCitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchCitiesQuery, SearchCitiesQueryVariables>(SearchCitiesDocument, options);
      }
export function useSearchCitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchCitiesQuery, SearchCitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchCitiesQuery, SearchCitiesQueryVariables>(SearchCitiesDocument, options);
        }
export function useSearchCitiesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SearchCitiesQuery, SearchCitiesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchCitiesQuery, SearchCitiesQueryVariables>(SearchCitiesDocument, options);
        }
export type SearchCitiesQueryHookResult = ReturnType<typeof useSearchCitiesQuery>;
export type SearchCitiesLazyQueryHookResult = ReturnType<typeof useSearchCitiesLazyQuery>;
export type SearchCitiesSuspenseQueryHookResult = ReturnType<typeof useSearchCitiesSuspenseQuery>;
export type SearchCitiesQueryResult = Apollo.QueryResult<SearchCitiesQuery, SearchCitiesQueryVariables>;
export const SearchDeliveryDocument = gql`
    query SearchDelivery {
  delivery {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useSearchDeliveryQuery__
 *
 * To run a query within a React component, call `useSearchDeliveryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchDeliveryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchDeliveryQuery({
 *   variables: {
 *   },
 * });
 */
export function useSearchDeliveryQuery(baseOptions?: Apollo.QueryHookOptions<SearchDeliveryQuery, SearchDeliveryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchDeliveryQuery, SearchDeliveryQueryVariables>(SearchDeliveryDocument, options);
      }
export function useSearchDeliveryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchDeliveryQuery, SearchDeliveryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchDeliveryQuery, SearchDeliveryQueryVariables>(SearchDeliveryDocument, options);
        }
export function useSearchDeliverySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SearchDeliveryQuery, SearchDeliveryQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchDeliveryQuery, SearchDeliveryQueryVariables>(SearchDeliveryDocument, options);
        }
export type SearchDeliveryQueryHookResult = ReturnType<typeof useSearchDeliveryQuery>;
export type SearchDeliveryLazyQueryHookResult = ReturnType<typeof useSearchDeliveryLazyQuery>;
export type SearchDeliverySuspenseQueryHookResult = ReturnType<typeof useSearchDeliverySuspenseQuery>;
export type SearchDeliveryQueryResult = Apollo.QueryResult<SearchDeliveryQuery, SearchDeliveryQueryVariables>;
export const FilteredProductsDocument = gql`
    query FilteredProducts($first: Int!, $after: String, $filter: ProductFilterInput) {
  products(first: $first, after: $after, filter: $filter) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        id
        title
        description
        rating {
          averageRating
        }
        imageUrl
        createdAt
        category {
          id
          name
          description
        }
        owner {
          displayName
          delivery {
            id
            name
          }
          account {
            location {
              city {
                id
                name
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useFilteredProductsQuery__
 *
 * To run a query within a React component, call `useFilteredProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilteredProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilteredProductsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFilteredProductsQuery(baseOptions: Apollo.QueryHookOptions<FilteredProductsQuery, FilteredProductsQueryVariables> & ({ variables: FilteredProductsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FilteredProductsQuery, FilteredProductsQueryVariables>(FilteredProductsDocument, options);
      }
export function useFilteredProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FilteredProductsQuery, FilteredProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FilteredProductsQuery, FilteredProductsQueryVariables>(FilteredProductsDocument, options);
        }
export function useFilteredProductsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FilteredProductsQuery, FilteredProductsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FilteredProductsQuery, FilteredProductsQueryVariables>(FilteredProductsDocument, options);
        }
export type FilteredProductsQueryHookResult = ReturnType<typeof useFilteredProductsQuery>;
export type FilteredProductsLazyQueryHookResult = ReturnType<typeof useFilteredProductsLazyQuery>;
export type FilteredProductsSuspenseQueryHookResult = ReturnType<typeof useFilteredProductsSuspenseQuery>;
export type FilteredProductsQueryResult = Apollo.QueryResult<FilteredProductsQuery, FilteredProductsQueryVariables>;
export const SaveColorThemeDocument = gql`
    mutation SaveColorTheme($themeType: String!) {
  saveColorTheme(themeType: $themeType)
}
    `;
export type SaveColorThemeMutationFn = Apollo.MutationFunction<SaveColorThemeMutation, SaveColorThemeMutationVariables>;

/**
 * __useSaveColorThemeMutation__
 *
 * To run a mutation, you first call `useSaveColorThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveColorThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveColorThemeMutation, { data, loading, error }] = useSaveColorThemeMutation({
 *   variables: {
 *      themeType: // value for 'themeType'
 *   },
 * });
 */
export function useSaveColorThemeMutation(baseOptions?: Apollo.MutationHookOptions<SaveColorThemeMutation, SaveColorThemeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveColorThemeMutation, SaveColorThemeMutationVariables>(SaveColorThemeDocument, options);
      }
export type SaveColorThemeMutationHookResult = ReturnType<typeof useSaveColorThemeMutation>;
export type SaveColorThemeMutationResult = Apollo.MutationResult<SaveColorThemeMutation>;
export type SaveColorThemeMutationOptions = Apollo.BaseMutationOptions<SaveColorThemeMutation, SaveColorThemeMutationVariables>;
export const MeDocument = gql`
    query Me {
  me {
    ... on MeSuccess {
      persona {
        ... on Persona {
          id
          email
          firstName
          lastName
          displayName
          avatarUrl
        }
      }
    }
    ... on Error {
      message
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export function useMeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($userInfo: ForgotPasswordInput!) {
  forgotPassword(userInfo: $userInfo) {
    ... on ForgotPasswordSuccess {
      message
    }
    ... on Error {
      message
    }
  }
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      userInfo: // value for 'userInfo'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const ConfirmMailDocument = gql`
    mutation ConfirmMail($token: String) {
  confirmMail(token: $token)
}
    `;
export type ConfirmMailMutationFn = Apollo.MutationFunction<ConfirmMailMutation, ConfirmMailMutationVariables>;

/**
 * __useConfirmMailMutation__
 *
 * To run a mutation, you first call `useConfirmMailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmMailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmMailMutation, { data, loading, error }] = useConfirmMailMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useConfirmMailMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmMailMutation, ConfirmMailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmMailMutation, ConfirmMailMutationVariables>(ConfirmMailDocument, options);
      }
export type ConfirmMailMutationHookResult = ReturnType<typeof useConfirmMailMutation>;
export type ConfirmMailMutationResult = Apollo.MutationResult<ConfirmMailMutation>;
export type ConfirmMailMutationOptions = Apollo.BaseMutationOptions<ConfirmMailMutation, ConfirmMailMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($userInfo: ResetPasswordInput!) {
  resetPassword(userInfo: $userInfo) {
    ... on ResetPasswordSuccess {
      result
    }
    ... on Error {
      message
    }
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      userInfo: // value for 'userInfo'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const SignUpDocument = gql`
    mutation SignUp($userInfo: SignUpUserInfoInput!) {
  signUp(userInfo: $userInfo) {
    ... on SignUpSuccess {
      persona {
        ... on Persona {
          id
          email
          firstName
          lastName
          displayName
          avatarUrl
        }
      }
    }
    ... on Error {
      message
    }
  }
}
    `;
export type SignUpMutationFn = Apollo.MutationFunction<SignUpMutation, SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      userInfo: // value for 'userInfo'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: Apollo.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, options);
      }
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>;