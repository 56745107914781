import { OAuth2Client } from '@badgateway/oauth2-client';
import type { TRole, TUser } from 'app/AppStateStore';
import { useDispatch } from 'app/AppStateStore';
import {
  ForgotPasswordError,
  ForgotPasswordSuccess,
  ProfileType,
  ResetPasswordError,
  ResetPasswordSuccess,
  SignUpError,
  SignUpSuccess,
  useConfirmMailMutation,
  useForgotPasswordMutation,
  useMeQuery,
  useResetPasswordMutation,
  useSignUpMutation,
} from 'generated/graphql-operations';
import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { setUser, signOut as signOutAction } from './store/userSlice';

type TAuthContext = {
  isAuthenticated: boolean;
  authError?: string | null;
  authenticatedUser?: TUser | null;
  signIn: (
    email: string,
    password: string,
    remember: boolean,
    role: TRole
  ) => Promise<void>;
  signUp: (
    displayName: string,
    email: string,
    password: string,
    passwordConfirmation: string,
    acceptTerms: boolean,
    role: TRole
  ) => Promise<void>;
  signOut: () => void;
  forgotPassword: (email: string, role: TRole) => Promise<string>;
  resetPassword: (
    password: string,
    passwordConfirm: string,
    token?: string
  ) => Promise<TRole | undefined>;
  confirmMail: (token?: string) => Promise<void>;
};

const AuthContext = createContext<TAuthContext>({} as TAuthContext);

const client = new OAuth2Client({
  // The base URI of your OAuth2 server
  //server: 'https://torteeqapi.ostapoff.eu',
  server: import.meta.env.VITE_API_URL,

  // OAuth2 client id
  clientId: import.meta.env.VITE_CLIENT_ID || '',

  // OAuth2 client secret. Only required for 'client_credentials', 'password'
  // flows. Don't specify this in insecure contexts, such as a browser using
  // the authorization_code flow.
  // clientSecret: '',

  // The following URIs are all optional. If they are not specified, we will
  // attempt to discover them using the oauth2 discovery document.
  // If your server doesn't have support this, you may need to specify these.
  // you may use relative URIs for any of these.

  // Token endpoint. Most flows need this.
  // If not specified we'll use the information for the discovery document
  // first, and otherwise default to /token
  tokenEndpoint: 'connect/token',

  // Authorization endpoint.
  //
  // You only need this to generate URLs for authorization_code flows.
  // If not specified we'll use the information for the discovery document
  // first, and otherwise default to /authorize
  authorizationEndpoint: 'connect/authorize',

  // OAuth2 Metadata discovery endpoint.
  //
  // This document is used to determine various server features.
  // If not specified, we assume it's on /.well-known/oauth2-authorization-server
  // discoveryEndpoint: '/.well-known/oauth2-authorization-server',
});

const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authenticatedUser, setAuthenticatedUser] = useState<TUser | null>(
    null
  );
  const [authError, setAuthError] = useState<string | null>(null);
  const dispatch = useDispatch();

  const [signUpMutation, { error: signUpError }] = useSignUpMutation();
  const [forgotPasswordMutation, { error: forgotPasswordError }] =
    useForgotPasswordMutation();
  const [resetPasswordMutation, { error: resetPasswordError }] =
    useResetPasswordMutation();
  const [confirmMailMutation, { error: confirmMailError }] =
    useConfirmMailMutation();

  const { data, refetch } = useMeQuery();

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    setAuthError(null);

    if (accessToken && data?.me.__typename === 'MeSuccess') {
      const persona = data?.me.persona;
      const user: TUser = {
        id: persona.id,
        email: persona.email,
        role: persona.__typename!,
        displayName: persona.displayName,
        photoURL: persona.avatarUrl || persona.displayName[0],
        shortcuts: [],
      };

      dispatch(setUser(user));

      setAuthenticatedUser(user);
      setIsAuthenticated(true);
    }

    if (data?.me.__typename === 'MeError') {
      if (accessToken && data.me.message === 'Invalid token') {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
      }
      setIsAuthenticated(false);
      setAuthError(data.me.message);
    }
  }, [dispatch, data, isAuthenticated]);

  const signIn = async (
    email: string,
    password: string,
    rememberMe: boolean,
    role: TRole
  ) => {
    const authResponse = await client.password({
      username: email,
      password: password,
      // Offline access is required to get refresh token
      scope: ['offline_access', 'openid', 'esti', `esti_${role.toLowerCase()}`],
    });

    localStorage.setItem('accessToken', authResponse.accessToken);

    if (rememberMe && authResponse.refreshToken) {
      localStorage.setItem('refreshToken', authResponse.refreshToken);
    }

    await refetch();
  };

  const signUp = async (
    displayName: string,
    email: string,
    password: string,
    passwordConfirmation: string,
    acceptTerms: boolean,
    role: TRole
  ) => {
    const createdUser = await signUpMutation({
      variables: {
        userInfo: {
          displayName,
          email,
          password,
          passwordConfirmation,
          acceptTerms,
          role: role.toUpperCase() as ProfileType,
        },
      },
    });

    if (signUpError) {
      throw new Error(signUpError.message);
    }

    const { signUp } = createdUser?.data || {};

    if (!createdUser || !signUp || signUp?.__typename === 'SignUpError') {
      throw new Error((signUp as SignUpError).message);
    }

    const { persona: user } = signUp as SignUpSuccess;

    const authResponse = await client.password({
      username: email,
      password: password,
      // Offline access is required to get refresh token
      scope: ['offline_access', 'openid', 'esti', `esti_${role.toLowerCase()}`],
    });

    localStorage.setItem('accessToken', authResponse.accessToken);

    if (authResponse.refreshToken) {
      localStorage.setItem('refreshToken', authResponse.refreshToken);
    }

    dispatch(
      setUser({
        id: user.id,
        email: user.email,
        role: user.__typename,
        displayName: user.displayName,
        photoURL: user.avatarUrl || user.displayName[0],
        shortcuts: [],
      })
    );

    setIsAuthenticated(true);
  };

  const signOut = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    dispatch(signOutAction());
    setIsAuthenticated(false);
  };

  const forgotPassword = async (email: string, role: TRole) => {
    const result = await forgotPasswordMutation({
      variables: {
        userInfo: {
          email,
          role: role.toUpperCase() as ProfileType,
        },
      },
    });

    if (forgotPasswordError) {
      throw new Error(forgotPasswordError.message);
    }

    const { forgotPassword } = result?.data || {};

    if (
      !forgotPassword ||
      forgotPassword?.__typename === 'ForgotPasswordError'
    ) {
      throw new Error((forgotPassword as ForgotPasswordError).message);
    }

    const { message } = forgotPassword as ForgotPasswordSuccess;

    return message;
  };

  const resetPassword = async (
    password: string,
    passwordConfirm: string,
    token?: string
  ) => {
    // HACK: This is a temporary solution to get role and token from url, delete ASAP
    let role: TRole | undefined;
    if (token) {
      if (token === 'consumer-np@esti.com') {
        role = 'Consumer';
      }

      if (token === 'producer-np@esti.com') {
        role = 'Producer';
      }
    }

    const result = await resetPasswordMutation({
      variables: {
        userInfo: {
          password,
          passwordConfirm,
          token,
        },
      },
    });

    if (resetPasswordError) {
      throw new Error(resetPasswordError.message);
    }

    const { resetPassword } = result?.data || {};

    if (!resetPassword || resetPassword?.__typename === 'ResetPasswordError') {
      throw new Error((resetPassword as ResetPasswordError).message);
    }

    const { result: success } = resetPassword as ResetPasswordSuccess;

    if (success) {
      return role;
    }

    // TODO: Translate this error
    throw new Error('Something went wrong');
  };

  const confirmMail = async (token?: string) => {
    if (!token) {
      throw new Error('No token has been provided.');
    }

    const result = await confirmMailMutation({
      variables: {
        token,
      },
    });

    if (confirmMailError) {
      throw new Error(confirmMailError.message);
    }

    const { confirmMail } = result?.data || {};

    if (!confirmMail) {
      // TODO: Translate this error
      throw new Error('Something went wrong');
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        authenticatedUser,
        authError,
        signIn,
        signUp,
        signOut,
        forgotPassword,
        resetPassword,
        confirmMail,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};

export default AuthProvider;
