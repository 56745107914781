import FuseMessage from '@fuse/core/FuseMessage';
import FuseSuspense from '@fuse/core/FuseSuspense';
import styled from '@mui/material/styles/styled';
import { TLayoutConfig, useSelector } from 'app/AppStateStore';
import CustomDialog from 'app/components/shared/CustomDialog';
import { selectLayoutConfig, selectRoutes } from 'app/store/fuse/settingsSlice';
import clsx from 'clsx';
import { FC, memo } from 'react';
import { RouteObject, useRoutes } from 'react-router-dom';
import FooterLanding from './components/FooterLanding';
import RightSideLanding from './components/RightSideLanding';
import ToolbarLanding from './components/ToolbarLanding';

const LandingLayout: FC<React.PropsWithChildren> = (props) => {
  const config = useSelector(selectLayoutConfig);
  const routes = useSelector(selectRoutes);

  return (
    <Root id="fuse-layout" className="w-full flex" config={config}>
      <div className="flex flex-col flex-auto min-w-0">
        <main
          id="fuse-main"
          className="flex flex-col flex-auto min-h-full min-w-0 relative"
        >
          {config.toolbar.display && (
            <ToolbarLanding
              className={clsx(
                config.toolbar.style === 'fixed' && 'sticky top-0',
                config.toolbar.position === 'above' && 'order-first z-40'
              )}
            />
          )}

          <div className="flex flex-col flex-auto min-h-0 relative z-10">
            <CustomDialog />

            <FuseSuspense>{useRoutes(routes as RouteObject[])}</FuseSuspense>

            {props.children}
          </div>

          {config.footer.display && (
            <FooterLanding
              className={
                config.footer.style === 'fixed' ? 'sticky bottom-0' : ''
              }
            />
          )}
        </main>
      </div>

      {config.rightSidePanel.display && <RightSideLanding />}
      <FuseMessage />
    </Root>
  );
};

const Root = styled('div')(({ config }: { config: TLayoutConfig }) => ({
  ...(config.mode === 'boxed' && {
    clipPath: 'inset(0)',
    maxWidth: `${config.containerWidth}px`,
    margin: '0 auto',
    boxShadow:
      '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
  }),
  ...(config.mode === 'container' && {
    '& .container': {
      maxWidth: `${config.containerWidth}px`,
      width: '100%',
      margin: '0 auto',
    },
  }),
}));

export default memo(LandingLayout);
