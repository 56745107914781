import Pagination from '@mui/material/Pagination';
import { styled } from '@mui/material/styles';
import { useFilteredProductsQuery } from 'generated/graphql-operations';
import { FC, useEffect, useState } from 'react';
import Error from '../Error';
import Loading from '../Loading';
import ProductCard from './ProductCard';

const PRODUCTS_PER_PAGE = 20;

const ProductsGrid: FC<TProductsGridProps> = ({
  city,
  category,
  delivery,
  search,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalProductsCount, setTotalProductsCount] = useState(0);

  const { loading, error, data } = useFilteredProductsQuery({
    variables: {
      first: PRODUCTS_PER_PAGE,
      filter: {
        search: search,
        city: { eq: city },
        category: { eq: category },
        delivery: { eq: delivery },
      },
    },
  });

  useEffect(() => {
    if (data) {
      setTotalProductsCount(data.products.edges?.length || 0);
    }
  }, [data]);

  if (loading) {
    return <Loading />;
  }

  if (error || !data?.products || !data.products.edges) {
    return <Error message="Can't retrieve products" />;
  }

  const products = data.products.edges.map((edge) => edge?.node);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * PRODUCTS_PER_PAGE;
  const paginatedProducts = products.slice(
    startIndex,
    startIndex + PRODUCTS_PER_PAGE
  );

  return (
    <div data-tid="products-list">
      <MainContainer>
        {paginatedProducts.map((product) => (
          <ProductContainer key={product?.id}>
            <ProductCard
              id={product?.id || ''}
              title={product?.title || ''}
              imageUrl={product?.imageUrl || ''}
              rating={product?.rating?.averageRating || 0}
              description={product?.description || ''}
              createdAt={product?.createdAt || new Date()}
              category={product?.category.name || ''}
              owner={product?.owner.displayName || ''}
            />
          </ProductContainer>
        ))}
      </MainContainer>
      <PaginationContainer data-tid="pagination">
        <Pagination
          count={Math.ceil(totalProductsCount / PRODUCTS_PER_PAGE)}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </PaginationContainer>
    </div>
  );
};

type TProductsGridProps = {
  search?: string;
  city?: string;
  category?: string;
  delivery?: string;
};

const MainContainer = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gap: '10px',
});

const ProductContainer = styled('div')({
  padding: '1.4rem',
  alignItems: 'center',
  gap: '10px',
});

const PaginationContainer = styled('div')({
  marginTop: '20px',
  display: 'flex',
  justifyContent: 'center',
});

export default ProductsGrid;
