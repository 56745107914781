import routes from 'app/configs/routesConfig';
import { TThemeTypes } from 'app/configs/themesConfig';
import i18n, {
  TLanguage,
  TSupportedLanguages,
  defaultLanguage,
  supportedLanguages,
} from 'i18n';
import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useRef,
  useSyncExternalStore,
} from 'react';
import { RouteObject } from 'react-router-dom';

let initialState: TStateStore = {
  fuse: {
    settings: {
      layout: {
        mode: 'container',
        containerWidth: 1280,
        navbar: {
          display: true,
          style: 'slide',
          folded: false,
          position: 'left',
        },
        toolbar: {
          display: true,
          style: 'fixed',
          position: 'below',
        },
        footer: {
          display: true,
          style: 'static',
        },
        leftSidePanel: {
          display: true,
        },
        rightSidePanel: {
          display: false,
        },
      },
      themeType: 'defaultDark',
      direction: 'ltr',
    },
    dialog: {
      state: false,
      options: {},
    },
    navbar: {
      open: true,
      foldedOpen: false,
      mobileOpen: false,
    },
  },
  message: {
    state: false,
    options: {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
      autoHideDuration: 2000,
      message: 'Hi',
    },
  },
  notificationPanel: {
    state: false,
  },
  chatPanel: {
    state: false,
  },
  routes,
  direction: i18n.dir(),
  lng: i18n.language,
  supportedLanguages,
  defaultLanguage,
};

const createStore = <TStore extends {}>(initialState: TStore) => {
  const useStoreData = () => {
    const store = useRef<TStore>(initialState);

    const subscribers = useRef(new Set<() => void>());
    const get = useCallback(() => store.current, []);
    const set = useCallback((action: (store: TStore) => void) => {
      action(store.current);
      subscribers.current.forEach((callback) => callback());
    }, []);

    const subscribe = useCallback((callback: () => void) => {
      subscribers.current.add(callback);
      return () => {
        subscribers.current.delete(callback);
      };
    }, []);

    return {
      get,
      set,
      subscribe,
    };
  };

  type UseStoreDataReturnType = ReturnType<typeof useStoreData>;

  const StoreContext = createContext<UseStoreDataReturnType | null>(null);

  const AppStateProvider: FC<PropsWithChildren> = (props) => {
    const storeData = useStoreData();

    return (
      <StoreContext.Provider value={storeData}>
        {props.children}
      </StoreContext.Provider>
    );
  };

  const useDispatch = () => {
    const store = useContext(StoreContext);

    if (!store) {
      throw new Error('No store data found!');
    }

    return store.set;
  };

  const useSelector = <SelectorOutput extends unknown>(
    selector: (state: TStore) => Readonly<SelectorOutput>
  ) => {
    const store = useContext(StoreContext);

    if (!store) {
      throw new Error('No store data found!');
    }

    const state = useSyncExternalStore(store.subscribe, () =>
      selector(store.get())
    );

    return state as Readonly<SelectorOutput>;
  };

  return {
    AppStateProvider,
    useDispatch,
    useSelector,
  };
};

export const { AppStateProvider, useDispatch, useSelector } =
  createStore(initialState);

export type TDispatchAction<TPayload> = (
  payload: TPayload
) => (store: TStateStore) => void;

export type TStateStore = {
  fuse: TFuse;
  user?: TUser;
  message: TMessage;
  notificationPanel: TNotificationPanel;
  chatPanel: TChatPanel;
  routes: RouteObject[];
  direction: 'ltr' | 'rtl';
  lng: string;
  supportedLanguages: ReadonlyArray<TLanguage>;
  defaultLanguage: TSupportedLanguages;
};

export type TChatPanel = {
  state: boolean;
};

export type TNotificationPanel = {
  state: boolean;
};

export type TMessage = {
  state: boolean;
  options: TMessageOptions;
};

export type TMessageOptions = {
  anchorOrigin?: {
    vertical: 'top';
    horizontal: 'center';
  };
  autoHideDuration?: number;
  message?: string;
  variant?: string;
};

export type TRole = 'Admin' | 'Moderator' | 'Consumer' | 'Producer';

export type TUser = {
  id: string;
  displayName: string;
  photoURL: string;
  email: string;
  shortcuts: string[];
  role: TRole;
};

export type TFuse = {
  settings: TSettings;
  dialog: {
    state: boolean;
    options: {}; // TODO: Understand what options can be
  };
  navbar: {
    open: boolean;
    foldedOpen: boolean;
    mobileOpen: boolean;
  };
};

export type TSettings = {
  layout: TLayoutConfig;
  themeType: keyof TThemeTypes;
  direction: 'ltr' | 'rtl';
};

export type TLayoutConfig = {
  mode: 'fullwidth' | 'boxed' | 'container';
  scroll?: 'content' | 'body';
  containerWidth: number;
  navbar: {
    display: boolean;
    position: 'left' | 'right';
    style: 'slide' | 'folded';
    folded: boolean;
  };
  toolbar: {
    display: boolean;
    style: 'fixed' | 'static';
    position: 'above' | 'below';
  };
  footer: {
    display: boolean;
    style: 'fixed' | 'static';
  };
  rightSidePanel: {
    display: boolean;
  };
  leftSidePanel: {
    display: boolean;
  };
};

export type TThemeMode = 'light' | 'dark';

export type TPageConfig = {
  settings?: DeepPartial<TSettings>;
  routes: RouteObject[];
};

export type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P];
};
