import { memo } from 'react';

import Divider from '@mui/material/Divider';
import styled from '@mui/material/styles/styled';

import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import packageJson from '../../../../package.json';

// TODO: Add URL normalization
const GQL_API_URL = `${import.meta.env.VITE_API_URL}ui/graphiql`;

const Version = () => {
  const { t } = useTranslation('footer');

  return (
    <Container>
      <span data-tid="version">
        {t('version', { version: packageJson.version })}
      </span>

      {/* TODO: Don't forget to remove reference to API */}
      <Divider orientation="vertical" flexItem className="mx-6" />
      {}
      <LinkItem data-tid="api" to={GQL_API_URL} target="_blank">
        API
      </LinkItem>
    </Container>
  );
};

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

const LinkItem = styled(NavLink)(({ theme }) => ({
  backgroundColor: 'transparent!important',
  color: theme.palette.text.primary,
  textDecoration: 'none',
  border: 'none!important',
}));

export default memo(Version);
